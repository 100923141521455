import { getAgeFromDateString, getBMI } from './constants';

export class Assessment {
    static GetAssessmentScore(patient) {
        const answers = patient?.patientProfile?.stopBangResult;
        if (!answers) return;

        const bmi = getBMI(answers.weightLbs, answers.heightInches);
        const age = getAgeFromDateString(patient.birthdate);

        return this.computeStopBangScore(answers, age, bmi);
    }

    static computeStopBangScore = (answers, age, bmi) => {
        let apneaScore = 0;
        for (const key in answers) {
            if (
                [
                    'isMale',
                    'doesSnoreLoudly',
                    'hasHypertension',
                    'doesStopBreathing',
                    'doesStopBreathing2',
                    'isTired',
                ].includes(key) &&
                answers[key]
            )
                apneaScore++;
        }

        if (age > 50) apneaScore++;
        if (bmi > 35) apneaScore++;
        return apneaScore;
    };
}
