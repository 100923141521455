import { useSelector } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import watchpatImageSrc from '../../images/sleep-study-product/watchpatOneNoBackground.png';
import { GemSleepPath } from '../../components/Diagnosis';
import { useCheckoutContext } from '../../components/Checkout/CheckoutContext';
import { useAddToCartMutation } from '../../app/services/cart';
import { useEffect } from 'react';
import { useState } from 'react';
import { useInsuranceContext } from '../../components/Insurance';
import posthog from 'posthog-js';

const GemSleepStudy = () => {
    const navigate = useNavigate();

    const { hstInCart } = useSelector((state) => state.cart);
    const { sleepStudySku } = useSelector((state) => state.catalog);
    const { hstOrder } = useSelector((state) => state.orders);

    const { insurance } = useInsuranceContext();
    const { setCheckoutStep } = useCheckoutContext();
    const [addToCart] = useAddToCartMutation();

    //Allows users to return to this page without being forced into the redirect from the useEffect
    const [addedToCart, setAddedToCart] = useState(false);

    //Used a useEffect here so that it doesn't navigate until the cart has been updated and the checkout is ready
    useEffect(() => {
        if (hstInCart && addedToCart) {
            if (!!insurance) {
                //Insurance Alternative Users
                if (
                    insurance?.insuranceType === 'ALTERNATIVE' &&
                    insurance?.insuranceStatus.toLowerCase() === 'active'
                ) {
                    setCheckoutStep('insurance-alternative');
                    //Users who have already put in their insurance information
                } else setCheckoutStep('insurance-review');
            } else {
                //Users with no insurance information
                setCheckoutStep('payment-choice');
            }
            navigate('/dashboard/checkout');
        }
    }, [hstInCart, navigate, setCheckoutStep, addedToCart, insurance]);

    return hstOrder ? (
        <Navigate to="/dashboard/diagnosis" replace={true} />
    ) : (
        <>
            <div className="layout-outlet-root grid grid-rows lg:grid-cols-[2fr_1fr] gap-x-12  max-w-5xl">
                <div className="p-4 space-y-4">
                    <div>
                        <h5 className="text-gem-purple font-bold text-[45px]">
                            At Home Sleep Study
                        </h5>
                        <p className="text-gem-purple text-[22px]">
                            Comprehensive sleep apnea testing from home
                        </p>
                    </div>
                    <p className="text-lg">
                        Skip the lab. The disposable sleep test measures your sleep metrics in one
                        night in your home. Our clinicians review your data and provide a diagnosis
                        and treatment plan.
                    </p>
                    <div className="flex space-x-2">
                        <span className="inline-block text-center rounded-xl bg-purple py-1 px-2 text-sm capitalize text-white">
                            FDA Cleared
                        </span>
                        <span className="inline-block text-center rounded-xl bg-purple py-1 px-2 text-sm capitalize text-white">
                            Free Shipping
                        </span>
                        <span className="inline-block text-center rounded-xl bg-purple py-1 px-2 text-sm capitalize text-white">
                            Many Insurances Accepted
                        </span>
                    </div>
                    <button
                        onClick={async () => {
                            if (!hstInCart) {
                                await addToCart([
                                    {
                                        quantity: 1,
                                        sku: sleepStudySku,
                                        single: true,
                                    },
                                ]);

                                posthog?.capture('Added sleep study to cart');
                            }
                            setAddedToCart(true);
                        }}
                        className="btn-primary w-full">
                        CONTINUE
                    </button>
                </div>
                <img
                    src={watchpatImageSrc}
                    alt="home sleep study"
                    className="relative mx-auto lg:mx-0 lg:top-[25%] max-w-[260px] "
                />
                <div className="p-4 space-y-4 text-sm">
                    <h5 className="font-heading pt-4 text-xl">
                        At home sleep study as simple as 1, 2, 3, 4, 5
                    </h5>
                    <ol className="list-decimal pl-6">
                        <li>Home sleep study (watch) is sent directly to your home. </li>
                        <li>Put watch on and set-up in a few simple steps.</li>
                        <li>SLEEP (ahhh!—in your own bed - no wires, cords, lab).</li>
                        <li>
                            Your sleep results will be reviewed by a GEM SLEEP Provider, we'll
                            determine the next steps in your treatment plan.
                        </li>
                        <li>
                            Dispose of your device, the Home Sleep Study only requires one night of
                            sleep.
                        </li>
                    </ol>
                    <p className="mt-4">
                        Specific requirements of employers and/or health plans for managing Sleep
                        Apnea may vary.
                    </p>
                </div>
            </div>
            <GemSleepPath
                descriptionText="The next step on your path is getting an official diagnosis through a home sleep study"
                pathLabels={[
                    { text: 'Sleep apnea assessment', centered: true },

                    { text: 'Sleep* Study', centered: false },
                    { text: 'The right treatment', centered: true },
                    { text: 'Personalized support', centered: true },
                ]}
                stepsCompleted={1}
                footer={
                    <p className="lg:w-[700px] xl:w-[900px]">
                        <span className="font-bold">*Virtual Visit may be required.</span> Insurance
                        health plans require a clinician visit prior to completing a sleep study.
                        It's a quick virtual visit and can be scheduled when you place your sleep
                        study order. We will submit a claim to insurance for both the visit and the
                        home sleep study.
                    </p>
                }
            />
        </>
    );
};

export default GemSleepStudy;
