import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppointmentSchedulingButton from '../../components/Appointments/AppointmentSchedulingButton.js';
import { SleepStudyDownloadLink } from '../../components/Diagnosis';
import YoutubeVideo from '../../components/YoutubeVideo/YoutubeVideo';
import { toggleModal } from '../../features/ui/uiSlice';
import { ReactComponent as PhoneIcon } from '../../images/icons/phone.svg';
import { formatDate, getMostRecentFileByType } from '../../utils/constants';
import AHICircle from './ahi-circle.js';

export const SleepStudyResult = ({ ahiNumber }) => {
    const dispatch = useDispatch();

    //Redux state values
    const { user } = useSelector((state) => state.auth);
    const { files } = useSelector((state) => state.files);
    const { dmeReferral } = useSelector((state) => state.ui);

    //Local State
    const [severitySentence, setSeveritySentence] = useState(null);
    const [pathStrokeColor, setPathStrokeColor] = useState('#7d72cc');
    const [videoId, setVideoId] = useState(null);

    //Derived Values
    const hasHypoxemia = user.diagnoses.some(
        (userDiagnosis) => userDiagnosis.diagnosis.icdCode === 'R09.02',
    );
    const hasSleepApnea = user.diagnoses.some(
        (userDiagnosis) => userDiagnosis.diagnosis.icdCode === 'G47.33',
    );

    const hasSleepStudy = getMostRecentFileByType(files, 'SLEEP_STUDY');

    const alreadyInTreatment =
        user?.patientProfile?.treatments?.find(
            (treatment) => treatment.isActive && treatment.treatmentType === 'CPAP',
        ) || dmeReferral?.referralStatus === 'REFERRED';

    //Set messaging based on AHI and diagnoses
    useEffect(() => {
        if (!ahiNumber) return;

        if (!hasSleepApnea) {
            setSeveritySentence('Test did not Identify');
            setPathStrokeColor('#00AA63');
            if (hasHypoxemia) {
                setVideoId('A9sWnonvM1k');
            } else {
                setVideoId('GL1cJRSqMMg');
            }
            return;
        }

        if (ahiNumber > 30) {
            setSeveritySentence('severe obstructive');
            setPathStrokeColor('#a6242f');
            if (hasHypoxemia) {
                setVideoId('znkzGO-DRwM');
            } else {
                setVideoId('TqYTvvdUUKU');
            }
            return;
        }

        if (ahiNumber >= 15) {
            setSeveritySentence('moderate obstructive');
            setPathStrokeColor('#7d72cc');
            if (hasHypoxemia) {
                setVideoId('znkzGO-DRwM');
            } else {
                setVideoId('UI_rWztyfjQ');
            }
            return;
        }

        if (ahiNumber < 15) {
            setSeveritySentence('mild obstructive');
            setPathStrokeColor('#7d72cc');
            if (hasHypoxemia) {
                setVideoId('znkzGO-DRwM');
            } else {
                setVideoId('UUn-jpvL4Wc');
            }
            return;
        }
    }, [ahiNumber, hasHypoxemia, hasSleepApnea]);

    return (
        <div>
            <p className="mb-9 max-w-[850px] text-sm lg:mb-14 lg:text-lg">
                Your sleep study tracked the number of times your airway collapsed completely
                (called an apnea) or partially (called a hypopnea) while you were asleep. This was
                used to calculate your AHI score, which indicates sleep apnea severity.
            </p>

            <div className="mb-5 lg:mb-0 lg:grid lg:grid-cols-[250px_1fr]">
                {ahiNumber && hasSleepApnea && (
                    <div className="relative m-auto w-[250px]">
                        <AHICircle
                            className="lg:scale-110"
                            pathStrokeColor={pathStrokeColor}
                            ahiNumber={ahiNumber}
                        />
                        <div className="absolute-center w-full text-center">
                            <span className="block font-heading leading-none tracking-widest lg:text-lg">
                                Breathing stopped
                            </span>
                            <span className="block font-heading text-8xl leading-none lg:my-3 lg:translate-y-1 lg:text-9xl lg:leading-[0.7]">
                                {ahiNumber}
                            </span>
                            <span className="block font-heading leading-none tracking-widest lg:text-lg">
                                Times Every hour
                            </span>
                        </div>
                    </div>
                )}
                <div className={hasSleepApnea ? 'lg:ml-14' : 'col-span-2'}>
                    {severitySentence && (
                        <div className="mb-5 text-2xl font-bold lg:mb-9 lg:text-5xl">
                            {hasSleepApnea && <p className="capitalize">You have </p>}
                            <motion.p style={{ color: pathStrokeColor }} className="capitalize">
                                {severitySentence}
                            </motion.p>
                            <p className="capitalize">{'sleep apnea'}</p>
                            {hasHypoxemia && (
                                <p className="capitalize">
                                    {hasSleepApnea ? 'with ' : 'but identified '}Sustained Hypoxemia
                                </p>
                            )}
                        </div>
                    )}
                    {!ahiNumber && (
                        <p className="font-heading text-4xl font-bold">
                            You have not yet been given an AHI score, check back later.
                        </p>
                    )}
                    <Link
                        to="/dashboard/treatment"
                        className="btn-primary w-full mb-2 lg:w-[265px] lg:mr-2">
                        See my treatment options
                    </Link>

                    {!hasSleepApnea && (
                        <button
                            className="btn-primary w-full mb-2 lg:mt-0 lg:w-[265px] lg:mr-2"
                            onClick={(ev) => {
                                dispatch(toggleModal('noApnea'));
                            }}>
                            Click to Learn More
                        </button>
                    )}
                    <SleepStudyDownloadLink styling="btn-primary group relative pr-10 mt-2 w-full lg:pr-12 lg:w-[265px] lg:mt-0 " />
                </div>
            </div>

            {ahiNumber && (
                <div className="relative my-12 lg:my-16 bg-white shadow-xl">
                    <div className="max-w-3xl px-5 py-9 lg:px-9 lg:py-14">
                        <h2 className="text-xl font-bold">
                            The GEM SLEEP clinical team has left you a message on the results of
                            your study and recommended next steps.
                        </h2>
                        <div className="my-6">
                            {videoId && (
                                <YoutubeVideo
                                    videoId={videoId}
                                    videoTitle={`Dr. Howell Gem Introduction`}
                                    enableTracking={false}
                                />
                            )}
                        </div>

                        <p className="mb-4 text-xs lg:text-sm">
                            Report reviewed by a GEM SLEEP Provider
                            {user?.patientProfile?.hstReviewedAt
                                ? 'on ' + formatDate(user?.patientProfile?.hstReviewedAt)
                                : '.'}
                        </p>
                        <div className="space-y-4 lg:flex lg:space-y-0 lg:space-x-4">
                            {hasSleepStudy && (
                                <>
                                    <SleepStudyDownloadLink styling="btn-primary group relative pr-10 lg:pr-12 w-full lg:w-1/3" />
                                    <a
                                        href={
                                            'https://support.mygemsleep.com/Interpret_your_Sleep_Study'
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        className="btn-shell group relative w-full lg:w-1/3">
                                        How to Interpret My Sleep Study
                                    </a>
                                </>
                            )}
                            <button
                                className="btn-shell group relative pr-10 lg:pr-12 w-full lg:w-1/3"
                                onClick={(ev) => {
                                    dispatch(toggleModal('contact'));
                                }}>
                                Talk to someone
                                <PhoneIcon className="absolute right-3 top-1/2 -translate-y-1/2 lg:right-5 pointer-events-none" />
                            </button>
                        </div>
                    </div>
                    {!alreadyInTreatment && (
                        <div className="flex items-center justify-between border-t border-gray-light py-5 px-5 lg:px-9">
                            <p className="text-xl font-bold pr-4">
                                <span className="font-heading text-lg tracking-widest text-gem-purple">
                                    Questions on your diagnosis?
                                </span>{' '}
                                <br />
                                Request a consult with a GEM SLEEP clinician.
                            </p>
                            <AppointmentSchedulingButton
                                label="Schedule now"
                                sku="DIACLINICAL"
                                styling="btn-primary flex items-center justify-center space-x-2 lg:ml-auto"
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
