import { useSelector } from 'react-redux';
import { useAddToCartMutation } from '../../app/services/cart';
import { ReactComponent as AddIcon } from '../../images/icons/add.svg';
import { formatSquarePrice } from '../../utils/constants';
import { BsCheckLg } from 'react-icons/bs';
import posthog from 'posthog-js';

function ReorderItem({ supplyItem }) {
    const { orderItems } = useSelector((state) => state.cart);
    const [addToCart] = useAddToCartMutation();

    const alreadyInCart = orderItems.find(({ variantId }) => variantId === supplyItem.id);

    return (
        <div
            className="text-center bg-white mb-4 space-y-6 items-center grid grid-cols-1 md:grid-cols-[120px_1fr_200px] md:gap-4 md:mb-0 md:text-left"
            key={supplyItem.id}>
            <img
                className="mx-auto h-20 w-20 object-contain object-center mt-2 lg:h-40 lg:w-40 "
                src={supplyItem.image}
                alt="item"
            />
            <div className="text-xs md:text-base grid grid-rows-[repeat(4, minmax(0, 1fr))] md:grid-rows-[[repeat(4, minmax(0, 1fr))] space-y-1 items-center">
                <p className="font-bold">{supplyItem.name} </p>
                <p className="rounded-xl bg-purple py-1 px-2 mx-2 text-[11px] font-bold capitalize leading-relaxed text-white md:text-xs md:mx-0">
                    {supplyItem.size}
                </p>
                <p>{supplyItem.description}</p>
                <div>
                    {supplyItem.notCoveredByInsurance && (
                        <p className="font-bold text-red">Not covered by insurance</p>
                    )}
                    <p className="font-bold md:text-sm self-start">
                        ${formatSquarePrice(supplyItem.price)}
                    </p>
                </div>
            </div>
            <button
                className="btn-primary w-full flex mx-auto items-center justify-center space-x-2"
                disabled={alreadyInCart}
                onClick={() => {
                    addToCart([
                        {
                            quantity: 1,
                            sku: supplyItem.sku,
                            single: true,
                        },
                    ]);

                    posthog?.capture('Added supply item to cart', { sku: supplyItem.sku });
                }}>
                <span className="text-nowrap pointer-events-none">
                    {alreadyInCart ? 'Added to cart' : 'Add to cart'}
                </span>
                {alreadyInCart ? (
                    <BsCheckLg className="pointer-events-none" />
                ) : (
                    <AddIcon className="flex pointer-events-none" />
                )}
            </button>
        </div>
    );
}

export default ReorderItem;
