import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '..';
import { toggleModal } from '../../features/ui/uiSlice';

import { useState } from 'react';
import RecommendedOption from './RecommendedOption';
import CustomOption from './CustomOption';
import {
    setMaskType,
    setSelectedProduct,
    setSelectedVariation,
} from '../../features/catalog/catalogSlice';

const maskData = [
    {
        type: 'full',
        title: 'Full Mask',
        blurb: 'Best for people who breathe out of their mouth while sleeping.',
        maskCategoryAttributeValue: 'Full Face Mask',
    },
    {
        type: 'nasal',
        title: 'Nasal Mask',
        blurb: 'Best for people who are side or stomach sleepers.',
        maskCategoryAttributeValue: 'Nasal Mask',
    },
    {
        type: 'pillow',
        title: 'Nasal Pillow',
        blurb: "Best for people who are active sleepers, the 'toss & turner'.",
        maskCategoryAttributeValue: 'Nasal Pillow',
    },
];

const get90DaySupplyDetails = (selectedProduct) => {
    if (selectedProduct) {
        const supplyDetails = Object.values(selectedProduct.customAttributeValues).find((attr) =>
            /90 Day Supply Details/gi.test(attr.name),
        );

        return supplyDetails?.stringValue || '90 day supply of everything you will need.';
    } else return '90 day supply of everything you will need.';
};

const getCPAPDescription = (bundle) => {
    const cpapDetails = Object.values(bundle?.customAttributeValues).find((attr) =>
        /CPAP Description/gi.test(attr.name),
    );

    return cpapDetails?.stringValue || '';
};

const getRecommendedMaskType = (catalogItems, recommendedSku) => {
    //Determine the item in the catalog corresponding to the recommended SKU
    const targetProduct = catalogItems.find((item) =>
        item.itemData.variations.some((v) => v.itemVariationData.sku === recommendedSku),
    );

    //Determine which mask category it contained, for basis of recommendation
    const targetProductMaskCategory = targetProduct
        ? Object.values(targetProduct.customAttributeValues)?.find(
              (att) => att.name === 'Mask Category',
          )
        : null;

    switch (targetProductMaskCategory?.stringValue) {
        case 'Full Face Mask':
            return 'full';
        case 'Nasal Mask':
            return 'nasal';
        case 'Nasal Pillow':
            return 'pillow';
        default:
            return null;
    }
};

const ProductOptions = ({ brand, recommendedBundleVariation, selectedBundle }) => {
    const dispatch = useDispatch();
    const {
        catalogItems,
        catalogOptions,
        cpapMachinesOptionID,
        maskType,
        recommendedSku,
        selectedProduct,
    } = useSelector((state) => state.catalog);
    const { modalsOpen } = useSelector((state) => state.ui);

    const [selectedTab, setSelectedTab] = useState('recommended');

    const recommendedMaskType = getRecommendedMaskType(catalogItems, recommendedSku);

    const getItemOptionDataById = (itemOptionId, itemOptionValueId = null) =>
        itemOptionValueId
            ? catalogOptions
                  .find((o) => o.id === itemOptionId)
                  ?.itemOptionData?.values?.find((val) => val.id === itemOptionValueId)
            : catalogOptions.find((o) => o.id === itemOptionId)?.itemOptionData;

    //Determine the cpap machine by the first variation (all variations contain same machine)
    const cpapMachineValueId =
        selectedBundle?.itemData.variations[0].itemVariationData?.itemOptionValues?.find(
            (opt) => opt.itemOptionId === cpapMachinesOptionID,
        )?.itemOptionValueId;

    //Determine its name from the catalog
    const cpapMachineName =
        getItemOptionDataById(cpapMachinesOptionID, cpapMachineValueId)?.itemOptionValueData
            ?.name ?? '---';

    const onReset = () => {
        dispatch(setSelectedProduct(selectedBundle.id));
        dispatch(setSelectedVariation(recommendedBundleVariation));
        dispatch(setMaskType(recommendedMaskType));
    };

    return (
        <>
            <nav className="mb-5 flex lg:mb-8">
                <button
                    className={`flex-1 border-b-2 py-2 font-heading font-bold tracking-widest transition duration-300 ease-out-quad hover:border-gem-green hover:opacity-100 lg:text-lg ${
                        selectedTab === 'recommended'
                            ? 'border-gem-green opacity-100'
                            : 'border-gray opacity-40'
                    }`}
                    onClick={() => {
                        setSelectedTab('recommended');
                        onReset();
                    }}>
                    Recommended for you
                </button>
                <button
                    className={`flex-1 border-b-2 py-2 font-heading font-bold tracking-widest transition duration-300 ease-out-quad hover:border-gem-green hover:opacity-100 lg:text-lg ${
                        selectedTab === 'custom'
                            ? 'border-gem-green opacity-100'
                            : 'border-gray opacity-40'
                    }`}
                    onClick={() => {
                        setSelectedTab('custom');
                    }}>
                    Customize bundle
                </button>
            </nav>

            <RecommendedOption
                cpapMachineName={cpapMachineName}
                getItemOptionDataById={getItemOptionDataById}
                maskData={maskData}
                recommendedBundleVariation={recommendedBundleVariation}
                recommendedMaskType={recommendedMaskType}
                selectedBundle={selectedBundle}
                selectedTab={selectedTab}
            />

            <CustomOption
                brand={brand}
                cpapMachineName={cpapMachineName}
                maskData={maskData}
                maskType={maskType}
                onReset={onReset}
                recommendedMaskType={recommendedMaskType}
                selectedTab={selectedTab}
            />

            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('cpapDetails') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('cpapDetails'));
                }}>
                <div className="space-y-4 text-gray-darker">
                    <h3 className="mb-4 font-heading text-4xl lg:text-7xl">{cpapMachineName}</h3>
                    <p className="text-[13px] lg:text-base">{getCPAPDescription(selectedBundle)}</p>
                </div>
            </Modal>
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('90day') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('90day'));
                }}>
                <div className="space-y-4 text-gray-darker">
                    <p className="text-[13px] lg:text-base">
                        {get90DaySupplyDetails(selectedProduct)}
                    </p>
                </div>
            </Modal>
        </>
    );
};

export default ProductOptions;
