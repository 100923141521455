import { useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { SleepStudyResult } from './SleepStudyResult';
import { PageHeader } from '../../components/LayoutComponents';

const Home = () => {
    const { user } = useSelector((state) => state.auth);
    const { uploadedCharts } = useSelector((state) => state.files);
    const { hstOrder } = useSelector((state) => state.orders);

    //Redirect to treatment page if they have finished their coaching review or need a retest
    const redirectToTreatment =
        user?.patientProfile?.needsSleepStudyRetest ||
        ['APPROVED', 'NOT_APPROVED'].includes(user?.patientProfile?.coachingStatus);

    const uploadedStudyPending =
        user?.patientProfile.mdStatus === 'UPLOADED_SLEEP_STUDY_PENDING_CLINICAL_REVIEW' ||
        (uploadedCharts && !redirectToTreatment);

    const needsSleepStudy = !hstOrder && !user?.patientProfile.ahi && !uploadedStudyPending;

    const studyInReview =
        !['HST_COMPLETE', 'UPLOADED_SLEEP_STUDY_APPROVED'].includes(
            user?.patientProfile.mdStatus,
        ) && !needsSleepStudy;

    const referredForTreatment =
        user?.patientProfile.referredForCpap ||
        user?.patientProfile.isDentalReferred ||
        user?.patientProfile.referToSleepClinic;

    return redirectToTreatment ? (
        <Navigate to="/dashboard/treatment" />
    ) : uploadedStudyPending ? (
        <Navigate to="study-in-review" />
    ) : needsSleepStudy ? (
        <Navigate to="get-diagnosed" />
    ) : studyInReview ? (
        <div className="layout-outlet-root">
            <PageHeader>Not quite yet!</PageHeader>
            <p className="mb-6 max-w-4xl text-sm lg:text-lg">
                Once your Sleep Study has been reviewed you will be able to see your diagnosis and
                download a copy of your sleep study report.
            </p>
            <Link to="/dashboard" className="btn-primary">
                Go back home
            </Link>
        </div>
    ) : (
        <>
            <div className="layout-outlet-root">
                <PageHeader>
                    {user?.firstName ? (
                        <span className="ph-no-capture">{user?.firstName + `'s `}</span>
                    ) : (
                        'Your '
                    )}
                    <span className="text-gray-six">diagnosis</span>
                </PageHeader>
                <SleepStudyResult ahiNumber={user?.patientProfile?.ahi} />
            </div>
            {referredForTreatment && (
                <div className="layout-outlet-root  bg-gray-darker py-12 text-white lg:py-24">
                    <div className="mb-8 lg:mb-16 lg:flex lg:items-end lg:justify-between">
                        <h3 className="mb-5 font-heading lg:mb-0">
                            <span className="text-xl text-gray-light lg:text-2xl">
                                The good news?
                            </span>
                            <br />
                            <span className="text-[44px] text-offwhite lg:text-7xl">
                                gem can help.
                            </span>
                        </h3>
                        <Link to="/dashboard/treatment" className="btn-primary w-full lg:w-auto">
                            See my treatment options
                        </Link>
                    </div>
                    <ul className="ml-4 list-disc">
                        <li>Reduce risk for heart attacks</li>
                        <li>Reduce risk for strokes</li>
                        <li>Control blood pressure</li>
                        <li>Increase alertness</li>
                        <li>Increase libido</li>
                        <li>Decrease anxiety</li>
                        <li>Improve overall mood</li>
                    </ul>
                </div>
            )}
        </>
    );
};

export default Home;
