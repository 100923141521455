import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { TiArrowRight, TiArrowLeft } from 'react-icons/ti';
import airfitF30 from '../../images/cpap/Airfit F30.png';
import airfitF40 from '../../images/cpap/Airfit F40.png';
import airtouchF20 from '../../images/cpap/Airtouch F20.png';
import f20 from '../../images/cpap/F20.png';
import f30i from '../../images/cpap/f30i.jpg';
import n30 from '../../images/cpap/N30.png';
import n30iNasal from '../../images/cpap/N30i Nasal.png';
import n20 from '../../images/cpap/Airfit N20.png';
import p30i from '../../images/cpap/P30i.png';
import p10 from '../../images/cpap/P10.jpg';
import reactSiestaFFM from '../../images/cpap/ReactSiestaFFM.png';
import reactNumaFFM from '../../images/cpap/ReactNumaFF.png';
import reactRioII from '../../images/cpap/ReactRioII.png';
import reactSiestaNM from '../../images/cpap/ReactSiestaNM.png';
import reactVivaNM from '../../images/cpap/ReactVivaNM.png';

const fullMaskSlides = [
    {
        img: airfitF30,
        title: 'ResMed AirFit F30',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-1">
                <li>Better for watching TV or reading in bed</li>
                <li>Allows for wearing glasses</li>
                <li>Can get claustrophobic </li>
            </ul>
        ),
    },
    {
        img: airfitF40,
        title: 'ResMed Airfit F40',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-1">
                <li>Latest mask technology</li>
                <li>Under the nose style</li>
                <li>Easy adjust headgear </li>
            </ul>
        ),
    },
    {
        img: f30i,
        title: 'ResMed AirFit F30i',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-1">
                <li>Allows for sleep in different positions</li>
                <li>Can get claustrophobic</li>
            </ul>
        ),
    },
    {
        img: airtouchF20,
        title: 'ResMed AirTouch F20',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-1">
                <li>Better for sensitive skin</li>
            </ul>
        ),
    },
    {
        img: f20,
        title: 'ResMed AirFit F20',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-1">
                <li>Better for stomach sleepers</li>
                <li>Better for allergies or stuffy noses at night</li>
            </ul>
        ),
    },
    {
        img: reactNumaFFM,
        title: 'React Numa',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-1">
                <li>Have a high CPAP Pressure Setting </li>
                <li>Cannot breath through nose</li>
                <li>Want a quieter mask</li>
            </ul>
        ),
    },
    {
        img: reactSiestaFFM,
        title: 'React Siesta',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-1">
                <li>Like to sit-up and watch TV/Read in Bed</li>
                <li>Cannot breath through nose</li>
                <li>Want more comfort</li>
            </ul>
        ),
    },
];

const nasalPillowSlides = [
    {
        img: p30i,
        title: 'ResMed AirFit P30i',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-1">
                <li>Better for stomach sleepers</li>
                <li>Better for watching TV in bed</li>
            </ul>
        ),
    },
    {
        img: p10,
        title: 'ResMed AirFit P10',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-1">
                <li>Optimal comfort, with minimal design</li>
                <li>Adjustable headgear</li>
                <li>No stiff frame</li>
            </ul>
        ),
    },
    {
        img: reactRioII,
        title: 'React Rio II',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-1">
                <li>Want a small/lightweight mask</li>
                <li>Sleep in different positions</li>
                <li>Want less pressure/marks on face </li>
            </ul>
        ),
    },
];

const nasalSlides = [
    {
        img: n30,
        title: 'ResMed AirFit N30',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-1">
                <li>Better for side sleepers</li>
                <li>Allows for wearing glasses</li>
                <li>Can get claustrophobic</li>
            </ul>
        ),
    },
    {
        img: n30iNasal,
        title: 'ResMed AirFit N30i',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-2">
                <li>Better for stomach sleepers</li>
            </ul>
        ),
    },
    {
        img: n20,
        title: 'ResMed AirFit N20',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-1">
                <li>Adaptable Silicone Cushion</li>
                <li>Moves with you as you sleep</li>
                <li>Reliable seal = less leaks</li>
            </ul>
        ),
    },
    {
        img: reactSiestaNM,
        title: 'React Siesta Nasal Mask',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-1">
                <li>Want a small mask</li>
                <li>Sleep in different positions</li>
                <li>Want less pressure/marks on face</li>
            </ul>
        ),
    },
    {
        img: reactVivaNM,
        title: 'React Viva',
        list: (
            <ul className="list-disc pl-4 text-xs lg:text-sm space-y-1">
                <li>Need easy forehead adjustment</li>
                <li>Sleep in different positions</li>
                <li>Do NOT wear glasses</li>
            </ul>
        ),
    },
];

function ComparisonSlider({ children, slides }) {
    const swiperRef = useRef();
    const [disabledButton, setDisabledButton] = useState('prev');
    return (
        <>
            <header className="flex items-start mb-5 lg:mb-6">
                <div className="flex-1">{children}</div>
                {slides.length > 2 && (
                    <nav className="flex items-center space-x-6">
                        <button
                            className="disabled:opacity-25"
                            onClick={() => {
                                swiperRef.current.slidePrev();
                            }}
                            disabled={disabledButton === 'prev'}>
                            <TiArrowLeft className="w-6 h-6 pointer-events-none" />
                        </button>
                        <button
                            className={`disabled:opacity-25 ${slides.length < 4 && 'sm:opacity-25'}`}
                            onClick={() => {
                                swiperRef.current.slideNext();
                            }}
                            disabled={disabledButton === 'next'}>
                            <TiArrowRight className="w-6 h-6 pointer-events-none" />
                        </button>
                    </nav>
                )}
            </header>
            {slides.length > 0 ? (
                <Swiper
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    onSlideChange={(swiper) => {
                        if (swiper.isBeginning) {
                            setDisabledButton('prev');
                            return;
                        }

                        if (swiper.isEnd) {
                            setDisabledButton('next');
                            return;
                        }

                        if (
                            swiper.activeIndex > 0 &&
                            swiper.activeIndex < swiper.slides.length - 1
                        ) {
                            setDisabledButton(null);
                            return;
                        }
                    }}
                    slidesPerView={2.25}
                    spaceBetween={15}
                    centeredSlides={false}
                    breakpoints={{
                        640: {
                            slidesPerView: 4,
                            spaceBetween: 50,
                            centeredSlides: false,
                        },
                    }}>
                    {slides.map((slide, idx) => (
                        <SwiperSlide key={`comparison-card-${idx}`} className="h-auto">
                            <div className="bg-white pb-[100%] mb-5 relative">
                                <img
                                    src={slide.img}
                                    alt=""
                                    className="object-center object-cover absolute-center"
                                />
                            </div>
                            <h5 className="font-heading font-bold text-lg lg:text-2xl mb-2">
                                {slide.title}
                            </h5>
                            {slide.list}
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : (
                'No slides.'
            )}
        </>
    );
}

function MaskComparisonGrid() {
    return (
        <ul className="divide-y divide-dashed divide-gray-light space-y-4 lg:space-y-8">
            <li>
                <ComparisonSlider slides={fullMaskSlides}>
                    <p className="font-bold lg:text-xl mb-3 lg:mb-4">Full Mask</p>
                    <ul className="list-disc pl-4 text-xs lg:text-base">
                        <li>For those that breathe through their mouth while sleeping</li>
                        <li>
                            Not best for people with a beard/facial hair, harder to get a tight seal
                        </li>
                    </ul>
                </ComparisonSlider>
            </li>
            <li className="pt-4 lg:pt-8">
                <ComparisonSlider slides={nasalSlides}>
                    <p className="font-bold lg:text-xl mb-3 lg:mb-4">Nasal Mask</p>
                    <ul className="list-disc pl-4 text-xs lg:text-base">
                        <li>Best for people who only breath out of their nose while sleeping</li>
                        <li>Better for those who have a bed partner</li>
                        <li>Takes a bit longer to get used to this kind of mask</li>
                    </ul>
                </ComparisonSlider>
            </li>
            <li className="pt-4 lg:pt-8">
                <ComparisonSlider slides={nasalPillowSlides}>
                    <p className="font-bold lg:text-xl mb-3 lg:mb-4">Nasal Pillow</p>
                    <ul className="list-disc pl-4 text-xs lg:text-base">
                        <li>For those who want the least 'bulk' and size on their face </li>
                    </ul>
                </ComparisonSlider>
            </li>
        </ul>
    );
}

export default MaskComparisonGrid;
