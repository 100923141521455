import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePostHog } from 'posthog-js/react';

import { useApproveOrRejectEstimateMutation } from '../../app/services/orders';
import { toggleModal } from '../../features/ui/uiSlice';
import Modal from '../../components/Modal/Modal';

function Estimate() {
    const posthog = usePostHog();
    const dispatch = useDispatch();
    const { estimateId } = useParams();
    const navigate = useNavigate();

    const [approveOrRejectEstimate] = useApproveOrRejectEstimateMutation();

    //Redux state
    const { orderHistory } = useSelector((state) => state.orders);
    const { storageToken } = useSelector((state) => state.auth);

    //Local state
    const [submitting, setSubmitting] = useState(false);
    const [approvedEstimate, setApprovedEstimate] = useState(false);

    const order = orderHistory?.find((order) => order.estimate?.id.toString() === estimateId);

    const estimateFileId = order?.estimate?.files.find((file) => file.fileType === 'ESTIMATE').id;

    const baseDownloadUrl = process.env.REACT_APP_API_BASE_URL
        ? process.env.REACT_APP_API_BASE_URL
        : '';

    return (
        <div className="max-w-xl space-y-4">
            <div>
                <h2 className="font-heading text-4xl sm:text-[44px]">GEM SLEEP CPAP ESTIMATE</h2>
                <p>
                    We worked with your insurance to create an estimate for your CPAP machine and 90
                    days of supplies. This estimate is valid for 30 days, we <b>must</b> receive
                    approval to ship your device.
                </p>
            </div>
            {estimateFileId && (
                <a
                    className="btn-primary text-base lg:text-lg w-full"
                    href={`${baseDownloadUrl}/files/${estimateFileId}?storageToken=${storageToken}`}
                    download
                    target="_blank"
                    rel="noreferrer">
                    View My Estimate
                </a>
            )}

            <p>
                If you have questions regarding your estimate, please{' '}
                <button onClick={() => dispatch(toggleModal('contact'))} className="link">
                    contact us
                </button>
            </p>

            <button
                className="btn-primary text-base lg:text-lg w-full"
                disabled={submitting}
                onClick={async () => {
                    setSubmitting(true);
                    const approval = await approveOrRejectEstimate({
                        estimateId,
                        fileId: estimateFileId,
                        status: 'APPROVED',
                    }).unwrap();

                    if (approval) {
                        setSubmitting(false);
                        setApprovedEstimate(true);

                        posthog?.capture('Estimate Approved', { estimateId: estimateId });
                    } else {
                        toast.error('Something went wrong, please try again.');
                        setSubmitting(false);
                    }
                }}>
                I APPROVE THE ESTIMATE
            </button>
            <button
                disabled={submitting}
                className="btn-shell text-base lg:text-lg w-full"
                onClick={async () => {
                    setSubmitting(true);
                    const approval = await approveOrRejectEstimate({
                        estimateId,
                        fileId: estimateFileId,
                        status: 'REJECTED',
                    }).unwrap();

                    if (approval) {
                        setSubmitting(false);
                        navigate('/dashboard');
                        posthog?.capture('Estimate Rejected', { estimateId: estimateId });
                    } else {
                        toast.error('Something went wrong, please try again.');
                        setSubmitting(false);
                    }
                }}>
                NOT AT THIS TIME
            </button>

            <p className="font-bold">Important Notes:</p>
            <ol className="lg:list-disc">
                {['PRIOR_AUTH_NEEDED', 'PRIOR_AUTH_AFTER_CONSULT'].includes(
                    order?.priorAuthStatus,
                ) && (
                    <li key="prior-auth-required">
                        Your insurance company requires we obtain a Prior Authorization (PA) before
                        your CPAP can ship, this process can take up to 15 business days. The PA
                        will be submitted once we have approval on the estimate.{' '}
                    </li>
                )}
                <li key="approval-notice">
                    By approving the estimate, you are agreeing to pay any patient balance due.
                    Payment plans are available.
                </li>
            </ol>
            <Modal
                showClose={true}
                isOpen={approvedEstimate}
                width="lg:w-[500px]"
                closeHandler={() => {
                    setApprovedEstimate(false);
                    navigate('/dashboard');
                }}>
                <h3 className="mb-3 font-heading text-3xl">We marked your estimate as approved!</h3>
            </Modal>
        </div>
    );
}

export default Estimate;
