import { motion, AnimatePresence } from 'framer-motion';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

const ProductAccordionItem = ({ title, isExpanded, toggle, children, theme = 'default' }) => {
    const panelVariants = {
        expanded: { opacity: 1, y: 0, height: 'auto' },
        collapsed: { opacity: 0, y: 50, height: 0 },
    };

    return (
        <motion.li className="overflow-hidden">
            <h4
                className="font-heading font-bold cursor-pointer text-xl lg:text-3xl leading-[0.95] flex items-center justify-between py-5 lg:py-6 group"
                onClick={() => {
                    toggle();
                }}>
                {title}
                <button
                    className={`pointer-events-none transition ease-out-quad duration-300 min-w-[40px] lg:min-w-[48px] w-10 h-10 lg:w-12 lg:h-12 border-2 border-gray-light ${
                        theme === 'light'
                            ? 'group-hover:border-green-400'
                            : 'group-hover:border-white'
                    } rounded-full relative`}>
                    <AiOutlinePlus
                        className={`transition ease-out-quad duration-300 absolute-center w-8 ${
                            isExpanded ? 'rotate-90 opacity-0' : ''
                        }`}
                    />
                    <AiOutlineMinus
                        className={`transition ease-out-quad duration-300 absolute-center w-8 ${
                            isExpanded ? '' : '-rotate-90 opacity-0'
                        }`}
                    />
                </button>
            </h4>
            <AnimatePresence initial={false}>
                {isExpanded && (
                    <motion.div
                        className={`space-y-5 ${theme === 'light' ? 'text-inherit' : 'text-gray-light'}`}
                        initial="collapsed"
                        animate="expanded"
                        exit="collapsed"
                        variants={panelVariants}
                        transition={{ ease: [0.5, 1, 0.89, 1], duration: 0.3 }}
                        aria-expanded={isExpanded}>
                        {children}
                        <p className="pb-3 lg:pb-6"></p>
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.li>
    );
};

export default ProductAccordionItem;
