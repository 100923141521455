import { useNavigate } from 'react-router-dom';
import { BiCheckCircle } from 'react-icons/bi';
import { useGetHomeStatus } from '../../utils/useGetHomeStatus/useGetHomeStatus';
import { AppointmentSchedulingButton } from '../../components/Appointments';

export const VariableUpNext = () => {
    const navigate = useNavigate();
    const variableContent = useGetHomeStatus();

    return (
        <div className="bg-white p-7 lg:p-8 drop-shadow-2xl">
            <h3 className="mb-3 font-heading text-5xl">{variableContent.title}</h3>
            {typeof variableContent.content === 'string' ? (
                <p className="mb-6 lg:mb-12 text-sm font-medium">{variableContent.content}</p>
            ) : (
                variableContent.content
            )}
            {variableContent.actionText &&
                (variableContent?.actionSchedulingSku ? (
                    <AppointmentSchedulingButton
                        styling="btn-primary flex w-full items-center justify-center text-center"
                        sku={variableContent.actionSchedulingSku}
                        label="Click to Schedule"
                    />
                ) : variableContent?.actionDestination?.indexOf('http') === 0 ? (
                    <a
                        href={variableContent.actionDestination}
                        className="btn-primary flex w-full items-center justify-center text-center"
                        target="_blank"
                        rel="noreferrer">
                        <span className="mr-3 leading-none">{variableContent.actionText}</span>
                        <BiCheckCircle className="scale-150" />
                    </a>
                ) : (
                    <button
                        className="btn-primary w-full"
                        onClick={() => {
                            if (variableContent.onClick) {
                                variableContent.onClick();
                            } else if (variableContent?.actionDestination !== '#') {
                                variableContent?.actionStateObject
                                    ? navigate(variableContent.actionDestination, {
                                          state: variableContent.actionStateObject,
                                      })
                                    : navigate(variableContent.actionDestination);
                            }
                        }}>
                        {variableContent.actionText}
                    </button>
                ))}
        </div>
    );
};
