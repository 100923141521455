import { useDispatch, useSelector } from 'react-redux';
import { useGetOrganizationQuery } from '../../app/services/insurance';
import { toggleModal } from '../../features/ui/uiSlice';
import { ProductImage } from '..';
import { ReactComponent as PhoneIcon } from '../../images/icons/phone.svg';
import denturesSrc from '../../images/dentures.jpg';
import topRightArrow from '../../images/top-right-arrow.svg';
import ProgressBadges from './ProgressBadges';

const OralTherapyTreatment = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { recommendedSku } = useSelector((state) => state.catalog);

    const {
        data: dentalAttribution,
        isFetching,
        isLoading,
    } = useGetOrganizationQuery(user?.patientProfile?.referToOrganizationId, {
        skip: !user?.patientProfile?.referToOrganizationId,
    });

    return (
        <>
            <h4 className="mb-7 flex items-center font-heading text-3xl lg:mb-12 lg:text-5xl">
                <span className="mr-4">oral appliance therapy</span>
                <ProgressBadges />
            </h4>

            <div className="mb-12 flex space-x-4">
                <div className="h-14 w-14 min-w-[56px] overflow-hidden rounded-full bg-white lg:h-24 lg:w-24 lg:min-w-[96px]">
                    <img src={denturesSrc} alt="" className="object-contain object-center" />;
                </div>
                {(isLoading || isFetching) && (
                    <p>
                        <b>Loading...</b> <br />
                        -------------------------
                        <br />
                        -------------------------
                        <br />
                        <a href="tel:+19523450290" className="font-bold text-green-hover">
                            -------------------------
                        </a>
                    </p>
                )}
                {dentalAttribution && (
                    <div className="space-y-1">
                        <p className="font-bold">{dentalAttribution.name}</p>
                        <p>{dentalAttribution.address}</p>
                        <p>{`${dentalAttribution.city}, ${dentalAttribution.state} ${dentalAttribution.zip}`}</p>
                        <p>
                            <a href={`mailto:${dentalAttribution.email}`}>
                                {dentalAttribution.email}
                            </a>
                        </p>
                        <p>
                            <a href={`tel:+1${dentalAttribution.phone.replace(/[- )(]/g, '')}`}>
                                {dentalAttribution.phone}
                            </a>
                        </p>
                    </div>
                )}
            </div>

            {user?.patientProfile?.referredForCpap && (
                <>
                    <p className="mb-4 font-semibold">Other treatments recommendations</p>

                    <div className="relative mb-10 items-center bg-white p-5 lg:flex lg:p-0 lg:pr-8">
                        <div className="relative min-w-[240px] lg:w-[240px]">
                            <ProductImage
                                sku={recommendedSku}
                                alt="A CPAP bundle mask"
                                className="object-contain object-center"
                            />
                        </div>
                        <div className="p-4 lg:py-8 lg:px-14">
                            <h5 className="mb-3 font-heading text-4xl lg:text-5xl">CPAP Therapy</h5>
                            <p className="mb-4 text-[13px] leading-normal lg:text-base">
                                Continuous Positive Airway Pressure, or CPAP, is the most common
                                treatment for sleep apnea.
                            </p>
                        </div>

                        <div className="flex min-w-[240px] flex-col space-y-3">
                            {!user?.patientProfile?.sovaSageConsultKey && !recommendedSku && (
                                <button
                                    className="btn-primary flex items-center justify-center space-x-4"
                                    onClick={() => {
                                        dispatch(toggleModal('maskFit'));
                                    }}>
                                    <span className="pointer-events-none">Get started</span>
                                    <img
                                        src={topRightArrow}
                                        alt=""
                                        className="w-2 lg:w-3 pointer-events-none"
                                    />
                                </button>
                            )}
                            {user?.patientProfile?.sovaSageConsultKey && !recommendedSku && (
                                <button
                                    className="btn-primary"
                                    onClick={() => {
                                        dispatch(toggleModal('maskFitLinkPending'));
                                    }}>
                                    Pending size review
                                </button>
                            )}

                            <button
                                className="btn-shell flex w-full items-center justify-center space-x-4 lg:w-auto"
                                onClick={() => {
                                    dispatch(toggleModal('contact'));
                                }}>
                                <span>Talk to someone</span>
                                <PhoneIcon className="pointer-events-none" />
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default OralTherapyTreatment;
