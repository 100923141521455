import { format, parse } from 'date-fns';
import React from 'react';

export const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return phoneNumberString;
};

/**
 *
 * @param {*} dateString DD/MM/YYYY
 * @returns ISO 8601 string
 */
export const dateToISOString = (dateString) => {
    const [mm, dd, yyyy] = dateString.split('/');
    return new Date(Date.UTC(parseInt(yyyy), parseInt(mm) - 1, parseInt(dd))).toISOString();
};

export const ignoreTimeZone = (dateISOString) => {
    const isoDate = new Date(dateISOString);
    const localOffsetFromUTCInMilliseconds = isoDate.getTimezoneOffset() * 60 * 1000;

    return new Date(
        isoDate.getTime() +
            localOffsetFromUTCInMilliseconds * Math.sign(localOffsetFromUTCInMilliseconds),
    );
};

// ignore timezone and return date string from iso8601 string
export const formatDate = (dateISOString, forInput = false) => {
    if (!dateISOString) return 'Unknown';
    const d = ignoreTimeZone(dateISOString);
    //Format to yyyy-mm-dd for inputs
    if (forInput) {
        return `${d.getFullYear()}-${(1 + d.getMonth()).toString().padStart(2, '0')}-${d
            .getDate()
            .toString()
            .padStart(2, '0')}`;
    } else return d.toLocaleDateString();
};

export const isValidUploadFile = (file) =>
    ['image/jpeg', 'image/pjpeg', 'image/png', 'application/pdf'].includes(file.type);

export const returnFileSize = (number) => {
    if (number < 1024) {
        return number + 'bytes';
    } else if (number >= 1024 && number < 1048576) {
        return (number / 1024).toFixed(1) + 'KB';
    } else if (number >= 1048576) {
        return (number / 1048576).toFixed(1) + 'MB';
    }
};

export const cleanFilename = (filename) => {
    //replace control, non-printable, and all characters outside of standard ascii with an underscore in the file name
    return filename.replace(/[^\u0020-\u007E]/g, '_');
};

export const hearAboutUsOptions = [
    {
        label: 'Google or other search engine',
        value: 'SEARCH_ENGINE',
    },
    {
        label: 'Social media',
        value: 'SOCIAL_MEDIA',
    },
    {
        label: 'Podcast/Radio',
        value: 'RADIO',
    },
    {
        label: 'Family member or friend referral',
        value: 'FAMILY_OR_FRIEND',
    },
    {
        label: 'Physician/Dentist',
        value: 'PHYSICIAN_OR_DENTIST',
    },
    {
        label: 'Insurance carrier website/representative',
        value: 'INSURANCE',
    },
    {
        label: 'Employer',
        value: 'EMPLOYER',
    },
    {
        label: 'Blog or publication',
        value: 'BLOG',
    },
    {
        label: 'Other',
        value: 'OTHER',
    },
];

export const states = [
    {
        label: 'Minnesota',
        value: 'MN',
    },
    {
        label: 'Wisconsin',
        value: 'WI',
    },
    {
        label: 'Alabama',
        value: 'AL',
    },
    {
        label: 'Alaska',
        value: 'AK',
    },
    {
        label: 'Arizona',
        value: 'AZ',
    },
    {
        label: 'Arkansas',
        value: 'AR',
    },
    {
        label: 'California',
        value: 'CA',
    },
    {
        label: 'Colorado',
        value: 'CO',
    },
    {
        label: 'Connecticut',
        value: 'CT',
    },
    {
        label: 'Delaware',
        value: 'DE',
    },
    {
        label: 'District of Columbia',
        value: 'DC',
    },
    {
        label: 'Florida',
        value: 'FL',
    },
    {
        label: 'Georgia',
        value: 'GA',
    },
    {
        label: 'Hawaii',
        value: 'HI',
    },
    {
        label: 'Idaho',
        value: 'ID',
    },
    {
        label: 'Illinois',
        value: 'IL',
    },
    {
        label: 'Indiana',
        value: 'IN',
    },
    {
        label: 'Iowa',
        value: 'IA',
    },
    {
        label: 'Kansas',
        value: 'KS',
    },
    {
        label: 'Kentucky',
        value: 'KY',
    },
    {
        label: 'Louisiana',
        value: 'LA',
    },
    {
        label: 'Maine',
        value: 'ME',
    },
    {
        label: 'Maryland',
        value: 'MD',
    },
    {
        label: 'Massachusetts',
        value: 'MA',
    },
    {
        label: 'Michigan',
        value: 'MI',
    },
    {
        label: 'Mississippi',
        value: 'MS',
    },
    {
        label: 'Missouri',
        value: 'MO',
    },
    {
        label: 'Montana',
        value: 'MT',
    },
    {
        label: 'Nebraska',
        value: 'NE',
    },
    {
        label: 'Nevada',
        value: 'NV',
    },
    {
        label: 'New Hampshire',
        value: 'NH',
    },
    {
        label: 'New Jersey',
        value: 'NJ',
    },
    {
        label: 'New Mexico',
        value: 'NM',
    },
    {
        label: 'New York',
        value: 'NY',
    },
    {
        label: 'North Carolina',
        value: 'NC',
    },
    {
        label: 'North Dakota',
        value: 'ND',
    },
    {
        label: 'Ohio',
        value: 'OH',
    },
    {
        label: 'Oklahoma',
        value: 'OK',
    },
    {
        label: 'Oregon',
        value: 'OR',
    },
    {
        label: 'Pennsylvania',
        value: 'PA',
    },
    {
        label: 'Rhode Island',
        value: 'RI',
    },
    {
        label: 'South Carolina',
        value: 'SC',
    },
    {
        label: 'South Dakota',
        value: 'SD',
    },
    {
        label: 'Tennessee',
        value: 'TN',
    },
    {
        label: 'Texas',
        value: 'TX',
    },
    {
        label: 'Utah',
        value: 'UT',
    },
    {
        label: 'Vermont',
        value: 'VT',
    },
    {
        label: 'Virginia',
        value: 'VA',
    },
    {
        label: 'Washington',
        value: 'WA',
    },
    {
        label: 'West Virginia',
        value: 'WV',
    },
    {
        label: 'Wyoming',
        value: 'WY',
    },
].sort((a, b) => (a.label > b.label ? 1 : -1));

export const apneaQuestions = [
    {
        answerKey: 'previouslyDiagnosedWithSleepApnea',
        type: 'yesno',
        subhead: null,
        title: 'Have you ever been diagnosed with sleep apnea?',
        content: null,
        skip: false,
    },
    {
        answerKey: 'howBroughtToGem',
        type: 'list',
        subhead: null,
        title: 'What brings you to gem sleep?',
        underhead: null,
        content: null,
        multiple: false,
        uniqueChoice: 'NONE',
        choices: [
            {
                value: 'LOOKING_FOR_OTHER_OPTIONS',
                label: 'I am using CPAP, but looking for an alternative treatment',
            },
            {
                value: 'NEED_NEW_MACHINE',
                label: 'I need a new CPAP machine and supplies',
            },
            {
                value: 'NEED_NEW_SLEEP_TEST',
                label: 'Need a new sleep test/prescription',
            },
            {
                value: 'STRUGGLE_WITH_CPAP',
                label: 'I need coaching to use my existing CPAP',
            },
            { value: 'OTHER', label: "My reason isn't listed here" },
        ],
        skip: false,
    },
    {
        answerKey: 'weCanHelp',
        type: null,
        subhead: null,
        title: 'We can help!',
        underhead: 'You are at the right place',
        modalLabel: null,
        content: 'To get started we first need to collect a little bit more information about you!',
        skipText: 'Continue',
        skipAsButton: true,
        modalContent: null,
        skip: false,
    },
    {
        answerKey: 'hasPriorStudy',
        type: 'yesno',
        subhead: null,
        title: 'Do you have a sleep study report you can upload?',
        underhead: '(less than 12 months old) ',
        content: null,
        modalLabel: 'Where can I get a copy of my sleep study',
        modalContent:
            'If you have access to an online portal through your prior sleep clinic (such as MyChart), you should be able to download a copy. If not, try reaching out to your clinic to have them send you a digital copy.',
        skip: false,
    },
    {
        answerKey: 'needNewSleepStudy',
        type: null,
        subhead: null,
        title: "Let's get you a new sleep study",
        modalLabel: null,
        content:
            "In order to proceed with using GEM SLEEP for treatment we need a valid sleep study. Let's finish setting up your account. In just a few short clicks you can order a new home sleep test.",
        skipText: 'Order a home sleep test',
        skipAsButton: true,
        modalContent: null,
        skip: false,
    },
    {
        answerKey: 'doesSnoreLoudly',
        type: 'yesno',
        subhead: null,
        subheadPreviouslyDiagnosed: 'While NOT using treatment,',
        title: 'Do you snore loudly?',
        titlePreviouslyDiagnosed: 'do you snore loudly?',
        modalLabel: 'How loud is loud?',
        content: null,
        skipText: null,
        modalContent:
            "If you snore louder than most people's indoor speaking voice—about 60 decibels—then sleep doctors consider you a loud snorer. It takes between 40 and 70 decibels to wake someone up, so you might also be a loud snorer if you've ever been a human alarm clock.",
        skip: false,
    },
    {
        answerKey: 'isTired',
        type: 'yesno',
        subhead: null,
        title: 'Are you tired during the day?',
        modalLabel: 'How tired are we talking?',
        content: null,
        skipText: null,
        modalContent:
            "You're prone to feeling sleepy, groggy or fatigued at any time of day, causing you to struggle to stay awake.",
        skip: false,
    },
    {
        answerKey: 'doesStopBreathing',
        type: 'yesno',
        subhead: 'Has anyone observed you:',
        subheadPreviouslyDiagnosed: 'While NOT using treatment, has anyone observed you:',
        title: 'stop breathing, or struggle to breath while sleeping?',
        modalLabel: 'How would I know?',
        content: null,
        skipText: null,
        modalContent:
            "People with sleep apnea may appear to be gasping for air or choking in their sleep, although they often don't notice it on their own. If you're unsure whether that happens to you, please select “No.”",
        skip: false,
    },
    {
        answerKey: 'doesStopBreathing2',
        type: 'yesno',
        subhead: null,
        title: 'When sleeping on your back, have you ever woken up gasping or choking?',
        modalLabel: 'How would I know?',
        content: null,
        skipText: null,
        modalContent:
            "People with sleep apnea may appear to be gasping for air or choking their sleep (typically during an apnea event), although they often don't notice it on their own. If you're unsure whether this happens to you, please select “No.”",
        skip: false,
    },
    {
        answerKey: 'isMale',
        type: 'yesno',
        subhead: null,
        yesNoOverride: ['Female', 'Male'],
        title: 'Are you...',
        modalLabel: 'Why do we ask this?',
        content: null,
        skipText: 'I prefer not to say',
        modalContent:
            'Population-based studies show men are two to three times more likely to have sleep apnea than women.',
        skip: false,
    },
    {
        answerKey: 'hasHypertension',
        type: 'yesno',
        subhead: null,
        title: 'Have you been diagnosed with high blood pressure (hypertension)?',
        modalLabel: 'Why is this important?',
        content: null,
        skipText: "I don't know",
        modalContent:
            'Hypertension is a medical condition where blood pressure is continuously too high. It is more common in the setting of obstructive sleep apnea. Please answer yes if you have been given this diagnosis by a clinical provider. This includes patients who are currently being treated for hypertension.',
        skip: false,
    },
    {
        answerKey: 'heightInches',
        type: 'height',
        subhead: null,
        title: 'How tall are you?',
        content: null,
        skip: false,
    },
    {
        answerKey: 'weightLbs',
        type: 'weight',
        subhead: null,
        title: 'How much do you weigh?',
        content: null,
        skip: false,
    },
    {
        answerKey: 'dateOfBirth',
        type: 'date',
        subhead: null,
        title: 'What is your date of birth?',
        content: null,
        skip: false,
    },
    {
        answerKey: 'highRiskSelections',
        type: 'checklist',
        subhead: null,
        title: 'Do any of the following Home Sleep Study exclusions apply to you?',
        titlePreviouslyDiagnosed: 'Do any of the following health conditions apply to you?',
        underhead: '(Check all that apply)',
        content: null,
        multiple: true,
        uniqueChoice: 'NONE',
        choices: [
            {
                value: 'HEART_OR_LUNG',
                label: 'Have serious heart or lung disease (examples: heart failure, stroke, COPD, condition requiring a pacemaker)',
            },
            {
                value: 'OPIOID_MEDS',
                label: 'Currently taking Opioid, Alpha Blocker or Short Acting Nitrate medications*',
            },
            { value: 'INSOMNIA', label: 'Severe Insomnia' },
            { value: 'NONE', label: 'None of the above' },
        ],
        choicesPreviouslyDiagnosed: [
            {
                value: 'NONCPAP_DEVICE_IN_USE',
                label: 'Currently using a BiPAP, Bilevel PAP, AVAPS, or ASV machine for treatment.',
            },
            {
                value: 'HEART_OR_LUNG',
                label: 'Have serious heart or lung disease (examples: heart failure, stroke, COPD, condition requiring a pacemaker)',
            },
            {
                value: 'OPIOID_MEDS',
                label: 'Currently taking Opioid, Alpha Blocker or Short Acting Nitrate medications*',
            },
            { value: 'INSOMNIA', label: 'Severe Insomnia' },
            { value: 'NONE', label: 'None of the above' },
        ],
        modalLabel: '*What are these medications?',
        modalContent:
            'The most common name brand/generic names for these medications are: Opioids (Oxycontin, Percocet), Alpha Blockers (Doxasoine, Prazosin, Terazosin), Short Acting Nitrates (Nitroglycerine). Taking one of these medications may affect the results of a Home Sleep Study.',
        skip: false,
    },
    {
        answerKey: 'highRiskSelections2',
        type: 'checklist',
        subhead: null,
        title: 'Do any of the following sleep conditions apply to you?',
        underhead: '(Check all that apply)',
        content: null,
        multiple: true,
        uniqueChoice: 'NONE',
        choices: [
            {
                value: 'RESTLESS_LEGS',
                label: 'Restless Legs Syndrome',
            },
            { value: 'INSOMNIA', label: 'Severe Insomnia' },
            {
                value: 'SLEEPWALKING',
                label: 'Sleepwalking',
            },
            { value: 'DREAM_ENACTMENT', label: 'Dream Enactment' },
            { value: 'Circadian', label: 'Circadian Rhythm Disorder ' },
            { value: 'REM_DISORDER', label: 'REM Sleep Behavior Disorder' },
            { value: 'Seizures', label: 'Sleep Seizures' },
            { value: 'PERIODIC_LIMB_MOVEMENT', label: 'Periodic Limb Movement Disorder' },
            { value: 'NONE', label: 'None of the above' },
        ],
        //Skip by default, ask for coaching only users
        skip: true,
    },
    {
        answerKey: 'gained20Lbs',
        type: 'yesno',
        subhead: 'Since your last sleep study, have you:',
        title: 'gained or lost 20+ lbs?',
        content: null,
        skipText: "I don't know",
        //Skip by default, ask for previously diagnosed
        skip: true,
    },
    {
        answerKey: 'situationChanged',
        type: null,
        subhead: null,
        title: "Let's get you set-up with a new Home Sleep Test!",
        modalLabel: null,
        content:
            'Based on the responses to your question, it appears your situation has changed since your last study. We want to make sure we can provide you with the best recommended treatment for your current situation. To do so, you will need to complete a new Home Sleep Test. Luckily, this process is very simple.',
        skipText: 'Get started',
        skipAsButton: true,
        modalContent: null,
        //Skip by default, show only if they have gained or lost 20 pounds
        skip: true,
    },
    {
        answerKey: 'lowScoreAdditions',
        type: 'checklist',
        subhead: null,
        title: 'Do any of the following apply to you? ',
        underhead: '(Check all that apply)',
        content: null,
        multiple: true,
        uniqueChoice: 'NONE',
        choices: [
            {
                value: 'FAMILY_HISTORY_OF_APNEA',
                label: 'Family have a history of sleep apnea (parents or siblings)',
            },
            { value: 'HEARTBURN_OR_ACID_REFLUX', label: 'Heartburn or acid reflux at night' },
            {
                value: 'FREQUENTLY_WAKE',
                label: 'Frequently wake up throughout the night',
            },
            { value: 'MORNING_HEADACHE', label: 'Headache in the morning' },
            { value: 'NONE', label: 'None of the above' },
        ],
        //Skip by default, ask only if score is 1 or 2 on previous stopbang
        skip: true,
    },
    //Produces the anything else popup
    { answerKey: 'anythingElse' },
    //Triggers calculating assessment result
    { answerKey: 'finishedAssessment' },
];

export const otherThingsItems = [
    {
        title: 'What is a home sleep study test?',
        content: (
            <>
                <p>
                    Advances in technology have created the possibility for individuals to capture
                    their sleep pattern data and transfer it to a doctor from the comfort of their
                    very own home… in their very own bed. No clinics, no wires, and no one watching
                    you sleep (except maybe your dog).
                </p>
                <p>
                    GEM utilizes a tool called WatchPAT&trade;, a small wearable device (similar to
                    a Fitbit or Apple Watch) that gathers the same information as a traditional
                    sleep study, but in a MUCH more comfortable way. We will mail the device to your
                    home, have you watch a short online tutorial about how to link it to your
                    smartphone, and BOOM, you're a sleep apnea expert. Well, ok, not an EXPERT, but
                    you're certainly ready to get evaluated by one.
                </p>
            </>
        ),
    },
    {
        title: 'Does GEM SLEEP take insurance?',
        content: (
            <>
                <p>
                    GEM SLEEP is actively working to accept insurance directly. Given the Home Sleep
                    Test and CPAP Machines/Supplies are for healthcare you may be able to:
                </p>
                <ul className="ml-4 list-disc">
                    <li>Use your HSA or FSA account to make the purchase</li>
                    <li>
                        Submit your receipts to your insurance company to count towards your
                        out-of-pocket deductible
                    </li>
                </ul>
                <p>
                    Join the{' '}
                    <a
                        href="https://mygemsleep.com/waitlist"
                        className="inline-block border-b-2 border-green-400">
                        GEM SLEEP waitlist
                    </a>{' '}
                    to be on our priority list for updates and exciting product offerings.{' '}
                </p>
            </>
        ),
    },
];

export const otherThingsDentalPracticeItems = [
    {
        title: 'What is a home sleep study test?',
        content: (
            <>
                <p>
                    Advances in technology have created the possibility for individuals to capture
                    their sleep pattern data and transfer it to a doctor from the comfort of their
                    very own home… in their very own bed. No clinics, no wires, and no one watching
                    you sleep (except maybe your dog).
                </p>
                <p>
                    GEM utilizes a tool called WatchPAT&trade;, a small wearable device (similar to
                    a Fitbit or Apple Watch) that gathers the same information as a traditional
                    sleep study, but in a MUCH more comfortable way. We will mail the device to your
                    home, have you watch a short online tutorial about how to link it to your
                    smartphone, and BOOM, you're a sleep apnea expert. Well, ok, not an EXPERT, but
                    you're certainly ready to get evaluated by one.
                </p>
            </>
        ),
    },
    {
        title: 'Does GEM SLEEP take insurance?',
        content: (
            <>
                <p>
                    GEM SLEEP is actively working to accept insurance directly. Given the Home Sleep
                    Test and CPAP Machines/Supplies are for healthcare you may be able to:
                </p>
                <ul className="ml-4 list-disc">
                    <li>Use your HSA or FSA account to make the purchase</li>
                    <li>
                        Submit your receipts to your insurance company to count towards your
                        out-of-pocket deductible
                    </li>
                </ul>
                <p>
                    Join the{' '}
                    <a
                        href="https://mygemsleep.com/waitlist"
                        className="inline-block border-b-2 border-green-400">
                        GEM SLEEP waitlist
                    </a>{' '}
                    to be on our priority list for updates and exciting product offerings.{' '}
                </p>
            </>
        ),
    },
    {
        title: 'Will my dentist be notified when my results are complete?',
        content: (
            <p>
                Your dentist will have access to your test results via GEM, but you will be schedule
                your next visit with them via the GEM Portal at a time that is convenient for you.
            </p>
        ),
    },
];

export const stepsIfRight = [
    'Understand where you’re at – Take our short assessment to see if GEM SLEEP is right for you. Then create a free account to unlock access to sleep apnea resources.',
    'Test your sleep – Complete a sleep apnea test at home. Receive a clinical diagnosis from a GEM SLEEP provider in just days.',
    'Start your treatment – Select a treatment customized for you. We’ll be here to support you as you get started and every step of the way.',
];

export const stepsIfRightDentalPractice = (dentalPracticeName) => [
    'Click the button above to indicate where you are at in your sleep journey.',
    'Tell us a little bit about yourself!',
    `GEM SLEEP will guide you to next steps in your journey! `,
];

/**
 * A linear interpolator for hexadecimal colors
 * @param {String} a
 * @param {String} b
 * @param {Number} amount
 * @example
 * // returns #7F7F7F
 * lerpColor('#000000', '#ffffff', 0.5)
 * @returns {String}
 */
export function lerpColor(a, b, amount) {
    const ah = +a.replace('#', '0x'),
        ar = ah >> 16,
        ag = (ah >> 8) & 0xff,
        ab = ah & 0xff,
        bh = +b.replace('#', '0x'),
        br = bh >> 16,
        bg = (bh >> 8) & 0xff,
        bb = bh & 0xff,
        rr = ar + amount * (br - ar),
        rg = ag + amount * (bg - ag),
        rb = ab + amount * (bb - ab);

    return '#' + (((1 << 24) + (rr << 16) + (rg << 8) + rb) | 0).toString(16).slice(1);
}

export const reactSelectCustomStyles = (theme = 'dark') => ({
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        paddingBottom: '0.125rem',
        paddingTop: '0.125rem',
        color: theme === 'light' ? 'inherit' : '#fff',
        fontWeight: 600,
        fontSize: 'inherit',
        ':hover': {
            cursor: 'pointer',
        },
    }),
    value: (provided) => ({
        ...provided,
        color: theme === 'light' ? 'inherit' : '#fff',
    }),
    input: (provided) => ({
        ...provided,
        color: theme === 'light' ? 'inherit' : '#fff',
        margin: 0,
        padding: 0,
    }),
    singleValue: (provided) => ({
        ...provided,
        color: theme === 'light' ? 'inherit' : '#fff',
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'transparent',
        borderRadius: 0,
        border: 0,
        height: '100%',
        borderBottom:
            theme === 'light'
                ? `1px solid ${state.hasValue ? 'rgba(43, 47, 58, 1)' : 'rgba(43, 47, 58, .4)'}`
                : `1px solid ${state.hasValue ? '#fff' : 'rgba(255,255,255,0.25)'}`,
        padding: 0,
        outline: 'none',
        boxShadow: 'none',
        ':hover': {
            borderColor: theme === 'light' ? 'rgba(43, 47, 58, 1)' : '#fff',
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: theme === 'light' ? 'rgba(43, 47, 58, 1)' : '#f0f2f5',
        opacity: 0.7,
        fontWeight: 400,
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: '#fff',
        borderRadius: 0,
        ':hover': {
            cursor: 'pointer',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isFocused ? '#fff' : '#000',
        fontSize: '14px',
        backgroundColor: state.isFocused ? '#19a756' : 'transparent',
        ':hover': {
            backgroundColor: '#19a756',
            color: '#fff',
            cursor: 'pointer',
        },
    }),
});

export const productOptionPanelVariants = {
    expanded: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0 },
};

export const overlayVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};

export const parentPageVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
};

export const formatOptionValue = (value) => {
    switch (value) {
        case 'Small':
            return 'S';
        case 'Medium':
            return 'M';
        case 'Large':
            return 'L';
        case 'Wide':
            return 'Wide';
        case 'Small-Wide':
            return 'Small Wide';
        case 'Small and Medium':
            return 'Small Medium';

        default:
            return value;
    }
};

/**
 *
 * @param {int} price In cents... Because reasons?
 * @returns
 */
export const formatSquarePrice = (price) => {
    if (!price || price === 0) return '0.00';
    return (price / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 *
 * @param {int} weight Weight in pounds
 * @param {int} height Height in inches
 * @returns
 */
export const getBMI = (weight, height) => {
    return !weight || !height ? null : ((703 * weight) / Math.pow(height, 2)).toFixed(1);
};

/**
 *
 * @param {string} dateString Date in ISO format
 * @returns
 */
export const getAgeFromDateString = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;

    return age;
};

export function getFormattedVariationOptionsBySku(sku, catalogItems, catalogOptions) {
    if (!catalogItems || !sku) return [];

    const targetVariation = catalogItems
        .find((item) => item.itemData?.variations?.some((v) => v.itemVariationData.sku === sku))
        ?.itemData.variations.find((v) => v.itemVariationData.sku === sku);

    if (!targetVariation || !targetVariation.itemVariationData.itemOptionValues) return [];

    const formattedOptions = targetVariation.itemVariationData.itemOptionValues.map((opt) => {
        const optData = catalogOptions.find((item) => opt.itemOptionId === item.id)?.itemOptionData;

        if (!optData) return [];

        const optValue = optData.values.find((d) => d.id === opt.itemOptionValueId)
            ?.itemOptionValueData.name;

        return {
            name: optData.name,
            value: optValue,
        };
    });

    return formattedOptions;
}

export const formatSizeStrings = (str) =>
    typeof str === 'string'
        ? str.replace('Small', 'S')?.replace('Large', 'L')?.replace('Medium', 'M')
        : '';

export const toDate = (date) => {
    return parse(date, 'yyyy-MM-dd HH:mm:ss xx', new Date());
};

export const formatAppointmentForDisplay = (appointment) => {
    if (!appointment) return { name: null, start: null, timeframe: null };

    const name = appointment.appointmentType;
    const start = format(new Date(appointment.startAt), 'EEEE M/d h:mm a');
    const timeframe = `${format(new Date(appointment.startAt), 'h:mm')}-${format(
        new Date(appointment.endAt),
        'h:mm a (z)',
    )}`;

    return { name, start, timeframe };
};

//Matching SKUs to the Healthie Names
export const appointmentSkus = new Map([
    ['DIACLINICAL', 'Diagnosis Review'],
    ['COMPLIANCE', 'Compliance Visit'],
    ['REQCONSULT', 'Initial Consultation'],
]);

export const MAX_DAYS_OUT_SCHEDULING = 60;

export function determineOrderCategories(order) {
    const orderItemCategories =
        order && order.orderItems ? order.orderItems.map((item) => item.itemCategory) : [];

    const hasDeposit = orderItemCategories.includes('DEPOSIT');
    const hasOtherItems = orderItemCategories.includes('OTHER');
    const isCpapOrder = orderItemCategories.includes('CPAP_SUPPLIES');
    const isHSTOrder = orderItemCategories.includes('SLEEP_STUDY');
    const isConsultOrder = orderItemCategories.includes('CLINICAL_CONSULTS');
    const isSuppliesOrder = orderItemCategories.includes('SUPPLIES');

    return {
        orderItemCategories,
        hasDeposit,
        hasOtherItems,
        isCpapOrder,
        isHSTOrder,
        isConsultOrder,
        isSuppliesOrder,
    };
}

export function determineItemCategoryTags(order) {
    const { hasDeposit, hasOtherItems, isCpapOrder, isHSTOrder, isConsultOrder, isSuppliesOrder } =
        determineOrderCategories(order);

    const tags = [];

    if (isConsultOrder) tags.push(<span key="consult-tag">Consult</span>);
    if (isHSTOrder) tags.push(<span key="hst-tag">HST</span>);
    if (isCpapOrder) tags.push(<span key="cpap-tag">CPAP</span>);
    if (isSuppliesOrder) tags.push(<span key="supplies-tag">SUPPLIES</span>);
    if (hasDeposit) tags.push(<span key="deposit-tag">DEPOSIT</span>);
    if (hasOtherItems) tags.push(<span key="other-tag">OTHER</span>);
    if (tags.length === 0)
        tags.push(
            <span key="empty-tag" className="text-gray">
                Empty
            </span>,
        );

    return tags.map((p, idx) => (
        <React.Fragment key={`tag-${idx}`}>
            {idx > 0 && <span key={`break-${idx}`}>, </span>}
            {p}
        </React.Fragment>
    ));
}

//Swap elements in an array
export function swapElements(arr, x, y) {
    if (arr.length > 1) {
        [arr[x], arr[y]] = [arr[y], arr[x]];
    }

    return arr;
}

export const getMostRecentFileByType = (files, fileType) => {
    if (!files || files?.length === 0) {
        return;
    }
    if (fileType === 'SLEEP_STUDY') {
        return (
            files
                .filter(
                    (file) =>
                        file.fileType === 'ITAMAR_SLEEP_STUDY' ||
                        file.fileType === 'UPLOADED_SLEEP_STUDY',
                )
                // sort by most recent
                .sort((f1, f2) => {
                    const d1 = new Date(f1.createdAt);
                    const d2 = new Date(f2.createdAt);
                    if (d1 > d2) return -1;
                    if (d1 < d2) return 1;
                    return 0;
                })
                .shift()
        );
    } else {
        return files
            .filter((file) => file.fileType === fileType)
            .sort((f1, f2) => {
                const d1 = new Date(f1.createdAt);
                const d2 = new Date(f2.createdAt);
                if (d1 > d2) return -1;
                if (d1 < d2) return 1;
                return 0;
            })
            .shift();
    }
};

export function determineUploadStep({
    coachingPathWithoutPrescription,
    requiresCharts,
    uploadedCharts,
    uploadedPrescription,
    uploadedSleepStudy,
}) {
    if (!uploadedSleepStudy) {
        return 'sleep study report';
    } else if (!uploadedPrescription && !coachingPathWithoutPrescription) {
        return 'prescription';
    } else if (requiresCharts && !uploadedCharts) {
        return 'clinical visit summary';
    } else {
        return null;
    }
}
