import { useState, useEffect } from 'react';
import Modal from '../Modal/Modal.js';
import { ReactComponent as PlayIcon } from '../../images/icons/play.svg';

const YoutubeVideo = ({ videoId, playButton, videoTitle, enableTracking = true }) => {
    const [videoActive, setVideoActive] = useState(false);

    const posterImageUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

    const fetchYoutubePlayerAPI = () => {
        if (window.YT || (window.YT && window.YT.Player)) return;

        new Promise((res, rej) => {
            var el = document.createElement('script');
            el.src = 'https://www.youtube.com/iframe_api';
            el.async = true;
            el.onload = (_) => {
                window.YT.ready(res);
            };
            el.onerror = rej;
            document.head.append(el);
        });
    };

    const playVideo = (e) => {
        e.preventDefault();
        setVideoActive(true);
    };

    useEffect(() => {
        if (!videoActive) return;

        //attach the player API to the iframe
        const el = document.querySelector(`.youtube-${videoId}`);

        if (window.YT && window.YT.Player && el) {
            new window.YT.Player(el, {
                videoId: videoId,
                width: '100%',
                playerVars: {
                    autoplay: 1,
                    modestbranding: 0,
                    rel: 0,
                    showinfo: 0,
                    controls: 0,
                    fs: 1,
                    origin: window.location.origin,
                },
                events: {
                    onReady: (e) => {
                        e.target.playVideo();
                        if (window.gtag && enableTracking) {
                            window.gtag('event', 'video_play', {
                                event_category: 'video',
                                event_label: videoTitle || 'unknown video',
                            });
                        }
                        //GA tracking
                    },
                    onStateChange: (e) => {
                        if (e.data === 0) {
                            setVideoActive(false);
                            if (window.gtag && enableTracking) {
                                window.gtag('event', 'video_complete', {
                                    event_category: 'video',
                                    event_label: videoTitle || 'unknown video',
                                });
                            }
                        }
                    },
                },
            });
        }
    }, [videoActive, videoId, videoTitle, enableTracking]);

    fetchYoutubePlayerAPI();

    return (
        <div className="w-full">
            <button
                className="rel flex-1 items-center w-full bg-no-repeat bg-cover bg-center pt-[80%] sm:pt-[60%] relative"
                onClick={playVideo}
                style={{ backgroundImage: `url(${posterImageUrl})` }}>
                <div className="absolute max-w-[200px] w-1/4 h-1/4 inset-0 m-auto pointer-events-none">
                    {playButton || (
                        <span className="inset-0 m-auto w-4/5 h-4/5 absolute text-white pointer-events-none">
                            <PlayIcon className="w-full h-full pointer-events-none" />
                        </span>
                    )}
                </div>
            </button>

            <Modal isOpen={videoActive} closeHandler={() => setVideoActive(false)}>
                <div className={`youtube-${videoId}`}></div>
            </Modal>
        </div>
    );
};

export default YoutubeVideo;
