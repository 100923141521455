import { Form, Formik } from 'formik';
import { motion } from 'framer-motion';
import Lottie from 'lottie-react';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import { FaCheck, FaExclamation } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from '..';
import { useCreateFeedbackMutation, useGetMeQuery } from '../../app/services/auth';
import { useAddToCartMutation } from '../../app/services/cart';
import { useGetMyInsuranceQuery } from '../../app/services/insurance';
import {
    useCreateReferralMutation,
    useCreateSovasageConsultMutation,
} from '../../app/services/orders';
import { useGetWebflowTermsQuery } from '../../app/services/webflow';
import { toggle, toggleModal } from '../../features/ui/uiSlice';
import fifteenDaysSrc from '../../images/15-days.png';
import thirtyDaysSrc from '../../images/30-days.png';
import sevenDaysSrc from '../../images/7-days.png';
import { ReactComponent as AddIcon } from '../../images/icons/add.svg';
import insuranceCard from '../../images/insurance-card.png';
import sleepStudyExample from '../../images/sleep-study-example.jpg';
import { ReactComponent as ThumbsDown } from '../../images/thumbs-down.svg';
import { ReactComponent as ThumbsUp } from '../../images/thumbs-up.svg';
import progressCelebrationJson from '../../utils/progress-celebration.json';
import { useGetFormattedSupplies } from '../../utils/useGetFormattedSupplies';
import { TextareaField } from '../../views/Assessment/Assessment';
import { ReorderItem } from '../Treatment';

const BadgeAnimation = ({ imageSrc }) => {
    return (
        <div className="relative mx-auto flex h-[250px] w-[250px] flex-col justify-center">
            <motion.div
                transition={{ delay: 0.35 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute-center z-0 h-full w-full">
                <Lottie
                    animationData={progressCelebrationJson}
                    loop={true}
                    className="scale-[2.5]"
                />
            </motion.div>
            <motion.img
                src={imageSrc}
                alt=""
                className="relative mx-auto w-40"
                transition={{ delay: 0.15 }}
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: [0.8, 1.5, 1] }}
                exit={{ opacity: 0 }}
            />
        </div>
    );
};

function AuthenticatedModals() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {
        agreedToInsuranceBenefits,
        dmeReferral,
        modalsOpen,
        simpleDynamicModalMessage,
        sovaSageConsultLink,
        paymentUrl,
    } = useSelector((state) => state.ui);
    const { user } = useSelector((state) => state.auth);
    const { recommendedCPAPBundles } = useSelector((state) => state.catalog);

    const [createFeedback] = useCreateFeedbackMutation();
    const [createSovasageConsult] = useCreateSovasageConsultMutation();
    const [createReferral, { isLoading: creatingReferral }] = useCreateReferralMutation();
    const { formattedSupplies } = useGetFormattedSupplies();
    const [assignmentOfBenefits, setAssignmentOfBenefits] = useState(undefined);
    const [addToCart] = useAddToCartMutation();
    const [disableSubmission, setDisableSubmission] = useState(false);
    const { data: webflowTerms, isLoading } = useGetWebflowTermsQuery();
    const { data: insurance, isloading: insuranceLoading } = useGetMyInsuranceQuery(user?.id, {
        skip: !user,
    });
    const { isloading: userInfoLoading, isFetching: fetchingUserData } = useGetMeQuery();

    //use a local state to temporarily store the nps score until submission
    const [tempNPS, settempNPS] = useState(user?.patientProfile?.npsScore || null);

    useEffect(() => {
        if (isLoading) return;
        setAssignmentOfBenefits(
            webflowTerms?.items?.find((t) => t.slug === 'assignment-of-benefit-responsibility'),
        );
    }, [webflowTerms, isLoading]);

    useEffect(() => {
        if (searchParams.entries().length === 0) return;

        const modalToTrigger = searchParams.get('modal');

        if (searchParams.get('reorderSupplies') === 'show')
            dispatch(toggleModal('reorderSupplies'));
        if (searchParams.get('7days') === 'show') dispatch(toggleModal('7days'));
        if (searchParams.get('notice') === 'show') dispatch(toggleModal('notice'));
        if (modalToTrigger) dispatch(toggleModal(modalToTrigger));
    }, [dispatch, searchParams]);

    return (
        <>
            {/* Shown on Diagnosis home page */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('noApnea') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('noApnea'));
                }}>
                <div className="space-y-4 text-gray-darker">
                    <p>
                        Your sleep study did not demonstrate evidence of sleep apnea. This is good
                        news and should reassure you that it is unlikely you have clinically
                        significant breathing issues while you sleep.
                    </p>
                    <p>
                        However, as we observed only one night of your life it is possible for a
                        home sleep test to miss more sleep apnea that may be captured with a follow
                        up investigation. Please let us know if you feel that your typical night of
                        sleep was not adequately captured during your sleep study. For example,
                        sometimes people will have less sleep from stress or insomnia the night of
                        their sleep test while others report that aches and pains kept them tossing
                        and turning all night. In these cases, it may be worthwhile to repeat a home
                        sleep test.
                    </p>
                    <p>
                        Alternatively, if you have already had more than one home sleep test or have
                        more serious concerns about your sleep we would recommend you visit a local
                        sleep doctor at a clinic.
                    </p>
                    <p>
                        For additional sleep resources, click on the GEM SLEEP Support page or
                        visit:{' '}
                        <a
                            href="https://sleepeducation.org"
                            className="border-b-2 border-gem-green font-semibold"
                            rel="noreferrer"
                            target="_blank">
                            https://sleepeducation.org
                        </a>
                        .{' '}
                    </p>
                </div>
            </Modal>

            {/* Toggled through the thumbs down on main page */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('badRating') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('badRating'));
                }}>
                <div className="space-y-4 text-gray-darker">
                    <h3 className="mb-4 font-heading text-4xl lg:text-7xl">
                        We're so sorry to hear that!
                    </h3>
                    <p className="text-sm font-bold lg:text-lg">
                        Can you please provide feedback on how we can improve your experience?
                    </p>

                    <Formik
                        initialValues={{ badFeedback: '' }}
                        onSubmit={(data) => {
                            createFeedback({
                                thumbsUp: false,
                                comment: data.badFeedback,
                            });

                            posthog?.capture('Submitted Feedback', {
                                thumbsUp: false,
                                comment: data.badFeedback,
                            });

                            dispatch(toggleModal('badRating'));
                        }}>
                        <Form>
                            <TextareaField
                                name="badFeedback"
                                id="badFeedback"
                                className="mb-8 h-40 w-full border border-gray-darker p-5 lg:mb-12 lg:px-6"
                                placeholder=""
                            />
                            <button className="btn-primary w-full" type="submit">
                                Submit
                            </button>
                        </Form>
                    </Formik>
                </div>
            </Modal>

            {/* Toggled through the thumbs up on main page */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('goodRating') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('goodRating'));
                }}>
                <div className="text-gray-darker">
                    <h3 className="mb-7 font-heading text-4xl lg:mb-12 lg:text-6xl">
                        <span className="text-gray-six">
                            If you were asked by a friend or family member,
                        </span>{' '}
                        how likely are you to recommend GEM SLEEP?
                    </h3>

                    <div className="mx-auto mb-7 lg:mb-12 lg:max-w-[80%]">
                        <div className="mb-3 grid grid-cols-5 gap-0.5 lg:mb-5 lg:grid-cols-10">
                            {Array.from({ length: 10 }, (_, i) => i + 1).map((n) => (
                                <button
                                    className={`rating-btn aspect-1 font-heading text-lg transition duration-300 ease-out-quad ${
                                        tempNPS === n && 'border-2 active'
                                    }`}
                                    key={`rating-${n}`}
                                    onClick={() => {
                                        settempNPS(n);
                                    }}>
                                    {n}
                                </button>
                            ))}
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-2 text-xs">
                                <ThumbsDown />
                                <span>0 = Not likely</span>
                            </div>
                            <div className="flex items-center space-x-2 text-xs">
                                <ThumbsUp />
                                <span>10 = Very likely</span>
                            </div>
                        </div>
                    </div>
                    <p className="text-sm font-bold lg:text-lg">
                        Do you have any additional feedback? (optional)
                    </p>
                    <Formik
                        initialValues={{ additionalFeedback: '' }}
                        onSubmit={(data) => {
                            createFeedback({
                                thumbsUp: true,
                                comment: data.additionalFeedback,
                                npsScore: tempNPS,
                            });

                            posthog?.capture('Submitted Feedback', {
                                thumbsUp: true,
                                comment: data.additionalFeedback,
                                npsScore: tempNPS,
                            });

                            dispatch(toggleModal('goodRating'));
                        }}>
                        <Form>
                            <TextareaField
                                name="additionalFeedback"
                                id="additionalFeedback"
                                className="mb-8 h-30 w-full border border-gray-darker p-5 lg:mb-12 lg:px-6"
                                placeholder=""
                            />
                            <button className="btn-primary w-full" type="submit">
                                Submit
                            </button>
                        </Form>
                    </Formik>
                </div>
            </Modal>

            {/* Shown in SleepStudyUpload or GetDiagnosed pages */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('sleepReport') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('sleepReport'));
                }}>
                <p className="mb-1 text-lg font-bold text-purple">What is a sleep study report?</p>
                <p className="mb-4">
                    You would have completed a sleep study in a lab or at home using a sleep study
                    device (such as a ring or watch). After the completion of your study you should
                    have been provided a Sleep Study Report. The report shows objective data from
                    one night of measured sleep; your report may include numbers like: AHI/RDI
                    Number, Total Sleep Time, Oxygen Level, etc.
                </p>
                <p className="mb-8">
                    The report should include the raw numbers, as well as, an
                    analysis/interpretation of your results by a sleep medicine doctor.
                </p>
                <p className="mb-1 text-lg font-bold text-purple">
                    Where would I find my sleep study report?
                </p>
                <p className="mb-4">
                    If you do not have a copy of your sleep study report, you can request one from
                    the clinic whom requested/performed your sleep study. In most cases you can also
                    find a copy in your Electronic Medical Record (example: MyChart).
                </p>
                <img src={sleepStudyExample} alt="Sleep study example" className="" />
            </Modal>

            {/* Unused */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('needStudy') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('needStudy'));
                }}>
                <p className="mb-1 text-lg font-bold text-purple">Need a new sleep study?</p>
                <p>
                    If you can't find your sleep study report or need a new one, please send an
                    email to{' '}
                    <a
                        className="border-b-2 border-green-400 font-bold"
                        href="mailto:info@gem.health">
                        info@gem.health
                    </a>
                    . We can help! We will contact you via phone to walk you through the next steps
                    in the process.
                </p>
            </Modal>

            {/* Shown in GetDiagnosed or SleepStudyUpload views  */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('problemsUploading') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('problemsUploading'));
                }}>
                <p className="mb-1 text-lg font-bold text-purple">Having problems uploading?</p>
                <ul className="ml-4 list-disc space-y-2">
                    <li>
                        Ensure the file you are trying to upload is a supported format: PDF, JPEG,
                        PNG
                    </li>
                    <li>You can only upload one document</li>
                    <li>
                        If you cannot find the file on your smart phone/device, you can also try
                        uploading it from a desktop computer.
                    </li>
                    <li>
                        If none of these tips work, contact{' '}
                        <a className="border-b-2 border-green-400" href="mailto:info@gem.health">
                            info@gem.health
                        </a>{' '}
                        and we will help walk you through the process.
                    </li>
                </ul>
            </Modal>

            {/* Shown to users who opt for DME referral for CPAP */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('dmeReferral') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('dmeReferral'));
                }}>
                <h3 className="mb-7 font-heading text-4xl lg:mb-12 lg:text-6xl">
                    BEGIN REFERRAL STEPS
                </h3>
                <p className="mb-4 text-sm font-bold text-purple lg:text-base">
                    {recommendedCPAPBundles?.length > 0
                        ? `To continue with sending a referral to ${insurance?.payer.dmeReferralOrganization?.name}, click the button below.`
                        : `To send a referral to ${insurance?.payer.dmeReferralOrganization?.name}, we first must find
                    the mask that will work best for you. Continue to our mask selection tool.`}
                </p>
                <button
                    disabled={fetchingUserData || creatingReferral}
                    className="btn-primary mt-4 w-full"
                    onClick={async () => {
                        const referral = await createReferral({
                            userId: user?.id,
                            referralStatus: 'REQUESTED',
                            referralType: 'DME',
                            organizationId: insurance.payer.dmeReferralOrganization.id,
                        });

                        if (referral?.data?.id) {
                            dispatch(toggleModal('dmeReferral'));
                            if (recommendedCPAPBundles?.length > 0) {
                                dispatch(toggleModal('sovaSageCompleted'));
                            } else {
                                dispatch(toggleModal('maskFit'));
                            }

                            posthog?.capture('Asked for DME referral', {
                                c: insurance.payer.dmeReferralOrganization.id,
                            });
                        } else {
                            toast.error('Something went wrong, please try again');
                        }
                    }}>
                    CONTINUE
                </button>
            </Modal>

            {/* Shown to users to begin Sova Sage mask fitting for CPAP */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('maskFit') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('maskFit'));
                }}>
                <h3 className="mb-7 font-heading text-4xl lg:mb-12 lg:text-6xl">
                    Find your perfect mask
                </h3>
                <p className="mb-4 text-sm font-bold text-purple lg:text-base">
                    Let's customize your CPAP set up by finding the best mask for you.
                </p>
                <p className="mb-4 text-sm lg:text-base">
                    There are a variety of mask options. The right mask depends on your unique face
                    structure, preferences and sleep style. Complete our virtual fitting experience,
                    and we will recommend the best option for you.
                </p>
                <p></p>
                <p className="mb-2 text-sm font-bold uppercase lg:text-base">
                    Here's how it works:{' '}
                </p>
                <ul className="ml-4 mb-4 list-disc space-y-1 pl-4 text-sm lg:text-base">
                    <li>
                        Receive a text message from us with a personalized link - For security the
                        link expires, please complete upon receiving.{' '}
                    </li>
                    <li>Answer a few questions about your sleep preferences </li>
                    <li>Take a selfie for accurate face measurement</li>
                </ul>
                <button
                    disabled={disableSubmission}
                    className="btn-primary lg:mt-4 w-full"
                    onClick={async () => {
                        setDisableSubmission(true);
                        createSovasageConsult()
                            .unwrap()
                            .then(() => {
                                dispatch(toggleModal('maskFitLinkSent'));
                                dispatch(toggleModal('maskFit'));
                                setDisableSubmission(false);
                                posthog?.capture('Mask Fit Started');
                            })
                            .catch((error) => {
                                toast.error('An error has occurred', { theme: 'dark' });
                                setDisableSubmission(false);
                            });
                    }}>
                    START VIRTUAL FITTING
                </button>
            </Modal>

            {/* Confirmation after previous modal that a link is sent to their phone */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('maskFitLinkSent') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('maskFitLinkSent'));
                }}>
                <p className="mb-1 font-heading tracking-widest text-purple lg:text-lg">
                    Step 2: Complete mask fitting questionnaire
                </p>
                <h3 className="mb-7 font-heading text-4xl lg:mb-12 lg:text-6xl">
                    The link below has also been sent to the phone number on your gem account.
                </h3>
                <p className="mb-4 text-sm font-bold text-purple lg:text-lg">
                    Click the link here if on a phone, otherwise find the link on your phone and
                    follow the steps on your mobile device to complete the sizing process.
                </p>
                <a
                    href={sovaSageConsultLink}
                    className="btn-primary lg:my-4 w-full"
                    target="_blank"
                    rel="noreferrer">
                    Start Your Fitting
                </a>
                <p>
                    Want to learn more about the Mask Fitting Process?{' '}
                    <a
                        href="https://www.youtube.com/watch?v=WuOtt00DFz4"
                        target="_blank"
                        rel="noreferrer">
                        View video
                    </a>
                </p>
            </Modal>

            {/* Shown in OralTherapyTreatment or Treatment home page when awaiting sova sage results  */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('maskFitLinkPending') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('maskFitLinkPending'));
                }}>
                <p className="mb-1 font-heading tracking-widest text-purple lg:text-lg">
                    Step 3: We are determining your mask size/style!
                </p>
                <h3 className="mb-5 font-heading text-4xl lg:mb-12 lg:text-6xl">
                    We are waiting to receive your measurements.
                </h3>
                <p className="mb-2 text-sm font-bold text-purple lg:text-lg">
                    Please follow the steps on the link that was sent to your phone.
                </p>
                <p className="mb-4 text-sm font-bold text-purple lg:text-lg">
                    If you already completed the assessment, please try refreshing your screen to
                    pull in your mask recommendation.
                </p>
            </Modal>

            {/* Shown when sova sage results arrive after fitting automatically */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('sovaSageCompleted') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('sovaSageCompleted'));
                    navigate('/dashboard');
                }}>
                {userInfoLoading || insuranceLoading ? null : dmeReferral ? (
                    <>
                        <h3 className="mb-7 font-heading text-4xl lg:text-6xl">SUCCESS!</h3>
                        <p className="mt-4">
                            We've got the information we need to send your referral to{' '}
                            {insurance?.payer?.name}'s CPAP supplier,{' '}
                            {insurance?.payer?.dmeReferralOrganization?.name}. Usually this will be
                            completed within the{' '}
                            <span className="font-bold">next 2-3 business days.</span>
                        </p>
                        <p className="mt-4">
                            You'll receive an email confirmation once your referral has been
                            submitted.
                        </p>
                        <Link
                            to="/dashboard/"
                            className="btn-primary mt-5"
                            onClick={() => {
                                dispatch(toggleModal('sovaSageCompleted'));
                                navigate('/dashboard');
                            }}>
                            CONTINUE
                        </Link>
                    </>
                ) : (
                    <>
                        <h3 className="mb-7 font-heading text-4xl lg:text-6xl">GREAT JOB!</h3>
                        <p className="mt-4">We have found the best mask for you!</p>
                        <Link
                            to="/dashboard/treatment"
                            className="btn-primary mt-5"
                            onClick={() => {
                                dispatch(toggleModal('sovaSageCompleted'));
                            }}>
                            See my recommended CPAP bundles!
                        </Link>
                    </>
                )}
            </Modal>

            {/* Toggled on CPAPTherapyReorder */}
            <Modal
                contentClasses="lg:pb-32"
                showClose={true}
                rootElements={
                    <button
                        className="btn-primary absolute bottom-0 left-0 right-0 w-full"
                        onClick={() => {
                            dispatch(toggleModal('reorderSupplies'));
                            dispatch(toggle({ key: 'cartOpen', override: true }));
                        }}>
                        go to cart
                    </button>
                }
                isOpen={modalsOpen.indexOf('reorderSupplies') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('reorderSupplies'));
                }}>
                <h3 className="font-heading text-[44px] lg:text-[64px]">Time to stock up!</h3>
                <p className="mb-5 text-xs lg:text-sm">
                    The following GEM SLEEP supplies are available to re-order. Don't see the
                    supplies you need? Please call us for help placing your order.
                </p>
                {formattedSupplies && (
                    <>
                        {formattedSupplies.map((supplyItem) => (
                            <ReorderItem supplyItem={supplyItem} key={supplyItem.id} />
                        ))}
                        <button
                            className="btn-shell mx-auto mb-10 flex items-center space-x-2 md:mx-0 md:ml-auto lg:mb-0"
                            onClick={() => {
                                addToCart(
                                    formattedSupplies.map((supplyItem) => {
                                        return {
                                            quantity: 1,
                                            sku: supplyItem.sku,
                                            single: true,
                                        };
                                    }),
                                );

                                formattedSupplies.forEach((supplyItem) => {
                                    posthog?.capture('Added supply item to cart', {
                                        sku: supplyItem.sku,
                                    });
                                });
                            }}>
                            <span className="pointer-events-none">Add all to cart</span>
                            <AddIcon className="pointer-events-none" />
                        </button>
                    </>
                )}
            </Modal>

            {/* Automatically shown for users on log in 7 days after starting CPAP  */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('7days') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('7days'));
                    dispatch(toggle({ key: 'wore7days', override: true }));
                }}>
                <div className="text-center">
                    <BadgeAnimation imageSrc={sevenDaysSrc} />
                    <h2 className="mb-3 font-heading text-5xl lg:text-7xl">Wahoo!</h2>
                    <p className="text-sm font-bold lg:text-lg">
                        You've made it through the first 7 days of treatment.
                    </p>
                    <p className="mb-8 text-[13px] lg:mb-12 lg:text-base">
                        We know it's an adjustment, but studies show that even a week of treatment
                        can improve daytime sleepiness and sleep quality. Keep up the great work!
                    </p>
                    <button
                        className="btn-primary w-full"
                        onClick={() => {
                            dispatch(toggleModal('7days'));

                            dispatch(toggle({ key: 'wore7days', override: true }));
                        }}>
                        Got it, thanks!
                    </button>
                </div>
            </Modal>

            {/* Automatically shown for users on log in 15 days after starting CPAP  */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('15days') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('15days'));

                    dispatch(toggle({ key: 'wore15days', override: true }));
                }}>
                <div className="text-center">
                    <BadgeAnimation imageSrc={fifteenDaysSrc} />

                    <h2 className="mb-3 font-heading text-5xl lg:text-7xl">Wahoo!</h2>
                    <p className="text-sm font-bold lg:text-lg">
                        You have made it through the first 15 days of treatment!
                    </p>
                    <p className="mb-8 text-[13px] lg:mb-12 lg:text-base">
                        You are a GEM! Your treatment is helping to keep your blood pressure down
                        and maintain healthy metabolism! Keep on rolling!
                    </p>
                    <button
                        className="btn-primary w-full"
                        onClick={() => {
                            dispatch(toggleModal('15days'));

                            dispatch(toggle({ key: 'wore15days', override: true }));
                        }}>
                        Got it, thanks!
                    </button>
                </div>
            </Modal>

            {/* Automatically shown for users on log in 30 days after starting CPAP  */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('30days') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('30days'));

                    dispatch(toggle({ key: 'wore30days', override: true }));
                }}>
                <div className="text-center">
                    <BadgeAnimation imageSrc={thirtyDaysSrc} />
                    <h2 className="mb-3 font-heading text-5xl lg:text-7xl">Wahoo!</h2>
                    <p className="text-sm font-bold lg:text-lg">
                        You've made it through the first 30 days of treatment!
                    </p>
                    <p className="mb-8 text-[13px] lg:mb-12 lg:text-base">
                        YOU DID IT! We know this wasn't easy, but this is a HUGE milestone, the
                        first 30 days are always the toughest! Keep it up so that your mind stays
                        sharp and your body feels better than ever!
                    </p>
                    <button
                        className="btn-primary w-full"
                        onClick={() => {
                            dispatch(toggleModal('30days'));

                            dispatch(toggle({ key: 'wore30days', override: true }));
                        }}>
                        Got it, thanks!
                    </button>
                </div>
            </Modal>

            {/* Dynamic modal to display simple messaged used in AvailableSlots component during appointment booking */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('ghSimpleDynamicModal') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('ghSimpleDynamicModal'));
                }}>
                <div className="space-y-5">
                    <p className="font-heading text-2xl font-bold">Error</p>
                    <p className="text-sm lg:text-lg">{simpleDynamicModalMessage}</p>
                </div>
            </Modal>

            {/* Unused */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('callMeBackForm') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('callMeBackForm'));
                }}>
                <div className="space-y-5">
                    <iframe
                        className="mx-auto min-h-[600px] w-full"
                        src={`${process.env.PUBLIC_URL}/call-me-back-form.html`}
                        title="Call me back form"
                        width="500"
                        height="400"
                        frameBorder="0"></iframe>
                </div>
            </Modal>

            {/* Unused */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('outOfPocketDetermination') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('outOfPocketDetermination'));
                }}>
                <div className="space-y-4 text-gray-darker">
                    <p className="font-bold text-gem-green">How is cost determined?</p>
                    <p>
                        As an in-network provider, your health plan has established rates for
                        services we provide.
                    </p>
                    <p>
                        <b>
                            We have estimated how much your health plan will pay of these rates and
                            the remaining amount that is your responsibility
                        </b>
                        . This is based on your health plan coverage and health plan usage;
                        including amounts you've spent towards your deductible for other healthcare
                        services. This out of pocket cost amount is an estimate, and may change when
                        the claim is paid. We will provide a refund or a bill for any difference at
                        that time.
                    </p>
                </div>
            </Modal>

            {/* Shown to users checking out with insurance if they click "How much will I owe?" */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('billedLater') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('billedLater'));
                }}>
                <div className="space-y-4 text-gray-darker">
                    <p className="font-bold text-gem-green">Why am I being billed later?</p>
                    <p>
                        Your health plan did not provide enough information back to determine your
                        patient responsibility amount at this time. As an in-network provider, your
                        health plan has established rates for services we provide.
                    </p>
                    <p>
                        <span className="font-bold">
                            Any remainder, as determined by your health plan, is your patient
                            responsibility amount.
                        </span>{' '}
                        The amount is based on the specifics of your health plan coverage and health
                        plan usage, including amounts you've spent towards your deductible for other
                        healthcare services. We will submit a claim to insurance on your behalf for
                        each service completed.
                    </p>
                    <p className="font-bold">
                        We will follow up with you after the claim is paid for any remaining amount
                        due. We will deduct any deposits or pre-payments from your final amount due.
                        A refund may be provided if pre-paid service are cancelled, see our{' '}
                        <a
                            className="link"
                            href="http://www.mygemsleep.com/terms/virtual-visit-cancellation-and-no-show-policy"
                            target="_blank"
                            rel="noreferrer">
                            appointment policy
                        </a>{' '}
                        for more details.
                    </p>
                    <p>
                        If you have questions or would like us to generate a manual estimate you can
                        contact us at 1-833-946-4GEM
                    </p>
                </div>
            </Modal>

            {/* Shown when adding insurance or confirming insurance alternative benefits */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('assignmentOfBenefits') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('assignmentOfBenefits'));
                }}>
                {assignmentOfBenefits && (
                    <div className="bg-white">
                        <div
                            className="fixed bg-white flex cursor-pointer items-center pb-2 w-[85%] sm:max-w-2xl -translate-y-1"
                            onClick={() => {
                                dispatch(toggle({ key: 'agreedToInsuranceBenefits' }));
                                if (!agreedToInsuranceBenefits)
                                    dispatch(toggleModal('assignmentOfBenefits'));
                            }}>
                            <span
                                className={`checkbox float-left h-6 w-6 flex-shrink-0 cursor-pointer appearance-none border-4 bg-contain bg-center bg-no-repeat align-top transition duration-200 focus:outline-none pointer-events-none ${
                                    agreedToInsuranceBenefits
                                        ? 'border-green-600 bg-green-600'
                                        : 'border-green-400 bg-transparent'
                                }`}></span>
                            <FaCheck
                                className={`checkmark pointer-events-none absolute left-3 w-2 -translate-x-1/2 scale-150 text-white opacity-0 transition ${
                                    agreedToInsuranceBenefits ? 'opacity-100' : ''
                                }`}
                            />
                            <span
                                className={`cursor-pointer text-xs lg:text-sm pl-2 pointer-events-none`}>
                                I agree to <b>the following</b>
                            </span>
                        </div>
                        <div
                            className="pt-8 sm:max-w-2xl "
                            dangerouslySetInnerHTML={{
                                __html: assignmentOfBenefits['main-content'],
                            }}></div>
                    </div>
                )}
            </Modal>

            {/* Shown when adding insurance or updating insurance during checkout when clicking 'Need Help?' */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('insuranceDetailHelp') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('insuranceDetailHelp'));
                }}>
                <div className="space-y-4 text-gray-darker">
                    <p className="font-bold text-gem-green">How can we help?</p>
                    <p>
                        Our team is here to help answer your questions. Pick below how you want to
                        connect with us.
                    </p>
                    <p>
                        <a href="tel:+18339464436">Call us</a>
                    </p>
                    <p>
                        <button
                            className="link"
                            onClick={() => {
                                dispatch(toggleModal('callMeBackForm'));
                            }}>
                            Call me back
                        </button>
                    </p>
                    <p>
                        <a href="mailto:info@gem.health">Email us</a>
                    </p>
                    <p className="link border-none">
                        Chat us <span className="font-normal">[click chat bubble below]</span>
                    </p>
                </div>
            </Modal>

            {/* Shown when adding insurance or updating insurance during checkout when clicking 'Where do I find this?' */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('whereToFindInsuranceInfo') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('whereToFindInsuranceInfo'));
                }}>
                <div className="space-y-4 text-gray-darker">
                    <p className="font-bold text-gem-green">
                        Every insurance card looks different!
                    </p>
                    <p>Here are some quick tips.</p>
                    <ul className="mb-5 list-disc space-y-4 pl-4 text-sm sm:text-base">
                        <li>
                            <b>Member ID</b> - look for a series of letters or numbers called Member
                            ID, Policy Number or even just ID number. This is not the same as the
                            group number.
                        </li>
                        <li>
                            <b>Group ID</b> - identifies your employer that sponsors the plan.
                        </li>
                        <li>
                            <b>Patient name</b> - check that your name matches the way it is spelled
                            on your card.
                        </li>
                        <li>
                            <b>Policy holder</b> - The policy holder is generally the person whose
                            employer provides the insurance.
                        </li>
                    </ul>
                    <img src={insuranceCard} alt="insurance card" className="mx-auto" />
                </div>
            </Modal>

            {/* Shown when users with an invoice sign in */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('payMyBill') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('payMyBill'));
                }}>
                <div className="text-gray-darker">
                    <p className="mb-4">
                        You currently have an unpaid balance with GEM SLEEP, please visit our
                        payment portal to submit payment.
                    </p>
                    <p className="mb-4">
                        Reminder, we also offer payment plans. If you have any questions please
                        contact us.
                    </p>
                    <p className="text-sm text-gray-600 mb-4">
                        *Note it may take up to 10 days for balance information to update.
                    </p>
                </div>
                <a
                    target="_blank"
                    href={paymentUrl}
                    className="bg-[#726AD3] hover:bg-purple text-white hover:text-black font-bold rounded-md p-2 flex items-center justify-center w-48 h-12 mx-auto">
                    <FaExclamation className="text-red mr-1 -ml-1 pointer-events-none" /> Pay my
                    Bill
                </a>
            </Modal>
        </>
    );
}

export default AuthenticatedModals;
