import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { useSelector } from 'react-redux';

const Modal = ({
    width,
    isOpen,
    closeHandler,
    children,
    rootElements,
    showClose = true,
    contentClasses = '',
    closeOnOutsideClick = true,
}) => {
    // Needed for useEffect not to override other overflow-hidden applications
    const { navOpen, accountOpen, cartOpen } = useSelector((state) => state.ui);

    const overlayVariants = {
        initial: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 },
    };

    const contentVariants = {
        initial: { y: '10%' },
        visible: { y: '0', transition: { duration: 0.5, ease: [0.5, 1, 0.89, 1] } },
        exit: { y: '-20%', transition: { duration: 0.5, ease: [0.5, 1, 0.89, 1] } },
    };

    // Stops background scroll when opened, removes when closed if other elements haven't also stopped scroll
    useEffect(() => {
        isOpen && document.body.classList.add('overflow-hidden');
        return () => {
            if (!accountOpen && !navOpen && !cartOpen)
                document.body.classList.remove('overflow-hidden');
        };
        // eslint-disable-next-line
    }, [isOpen]);

    return createPortal(
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    className="fixed top-0 left-0 right-0 bottom-0 z-50 flex flex-col items-center justify-center bg-purple-dark/80 backdrop-blur-md"
                    initial="initial"
                    animate="visible"
                    exit="exit"
                    variants={overlayVariants}
                    onClick={() => {
                        if (closeOnOutsideClick) {
                            closeHandler();
                        }
                    }}>
                    <motion.div
                        className={`grid grid-cols-1 max-h-[80vh] max-w-[90%] lg:max-w-3xl bg-white lg:h-auto ${
                            width ? width : 'w-full lg:w-3/4'
                        } `}
                        variants={contentVariants}
                        onClick={(e) => e.stopPropagation()}>
                        {showClose && (
                            <div className="relative flex justify-end bg-white px-4 pt-5 lg:mx-0">
                                <button
                                    onClick={() => {
                                        closeHandler();
                                    }}
                                    className="text-2xl text-green-400">
                                    <IoCloseSharp className="pointer-events-none" />
                                </button>
                            </div>
                        )}
                        <div
                            className={`modal-content relative break-words bg-white px-4 pb-6 lg:mx-0 lg:px-12 lg:pb-12 ${contentClasses} overflow-y-auto`}>
                            {children}
                        </div>
                        {rootElements}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>,
        document.getElementById('root'),
    );
};

export default Modal;
