import { AnimatePresence, motion } from 'framer-motion';
import { useRef, useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { ReactComponent as FileUploadIcon } from '../../images/file-upload-icon.svg';
import { ReactComponent as FileChosenIcon } from '../../images/file-chosen-icon.svg';
import UploadSpinner from './UploadSpinner';
import { isValidUploadFile, returnFileSize } from '../../utils/constants';
import { DirectUpload } from '@rails/activestorage';
import { cleanFilename } from '../../utils/constants';

const UploadFile = ({
    isModal = false,
    uploadSuccessCallback,
    uploadButtonOverride = null,
    fileType = '',
    patientId = null,
    theme,
    type,
    multipleUploads = false,
}) => {
    const { storageToken } = useSelector((state) => state.auth);
    const formRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [currentFiles, setCurrentFiles] = useState([]);
    const [uploadError, setUploadError] = useState(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const { user } = useSelector((state) => state.auth);

    class FileUploader {
        constructor(file, url, progressCallback) {
            const cleanedFile = new File([file], cleanFilename(file.name), { type: file.type });

            // ActiveStorage...?
            this.upload = new DirectUpload(cleanedFile, url, this);
            this.progressCallback = progressCallback;
        }

        uploadFile() {
            return new Promise((resolve, reject) => {
                this.upload.create((error, blob) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(blob.signed_id);
                    }
                });
            });
        }

        directUploadWillStoreFileWithXHR(request) {
            request.upload.addEventListener('progress', (event) =>
                this.directUploadDidProgress(event),
            );
        }

        directUploadDidProgress(event) {
            const progress = (event.loaded / event.total) * 100;
            this.progressCallback(progress);
        }
    }

    const onChangeHandler = (e) => {
        const files = Array.from(e.target.files).filter((file) => isValidUploadFile(file));

        if (!files) {
            setUploadError('Invalid file type.');
            return;
        }
        setUploadError(null);
        setCurrentFiles(files);
    };

    const submitHandler = (ev) => {
        ev.preventDefault();
        if (currentFiles.length === 0) return;
        setIsUploading(true);

        const url = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : '';

        Promise.all(
            currentFiles.map((f) => {
                const UploaderInstance = new FileUploader(
                    f,
                    `${url}/files/${patientId || user.id}/${fileType}/${storageToken}`,
                    setProgress,
                );

                return UploaderInstance.uploadFile();
            }),
        )
            .then((fileIds) => {
                setUploadSuccess(true);

                if (multipleUploads) {
                    uploadSuccessCallback(fileIds);
                } else {
                    fileIds.forEach((fileId) => {
                        uploadSuccessCallback(fileId);
                    });
                }
            })
            .catch((err) => {
                if (typeof err === 'string') setUploadError(err);
            });
    };

    const removeFile = () => {
        setIsUploading(false);
        setCurrentFiles([]);
        formRef.current.elements.testupload.value = null;
    };

    return !storageToken ? (
        <div className="py-10 lg:py-14 px-8 relative border border-dashed border-black bg-white text-black">
            Storage token not found.
        </div>
    ) : (
        <>
            {uploadError && <p className="font-bold text-red text-sm mb-4">{uploadError}</p>}
            <form className="overflow-hidden" onSubmit={submitHandler} ref={formRef}>
                <div
                    className={`${
                        theme === 'slim' ? 'p-4 lg:py-8' : 'px-4 py-2 xs:py-6 lg:py-14'
                    } relative border border-dashed border-black bg-white text-black group xs:min-h-[164px]`}>
                    <input
                        className="block absolute w-full top-0 left-0 right-0 bottom-0 z-20 appearance-none opacity-0 cursor-pointer"
                        type="file"
                        name="testupload"
                        id="testupload"
                        accept="image/jpeg, image/png, application/pdf"
                        multiple={multipleUploads}
                        onChange={onChangeHandler}
                    />
                    <AnimatePresence mode="wait">
                        {currentFiles.length === 0 && !isUploading && (
                            <motion.div
                                key="file-upload-section"
                                className="text-center relative z-10"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}>
                                {theme !== 'slim' && (
                                    <FileUploadIcon className="inline-block mb-2 xs:mb-4 h-[30px] xs:h-[40px] lg:h-[60px]" />
                                )}
                                <>
                                    <p
                                        className={`transition font-bold text-green-400 ${
                                            theme === 'slim'
                                                ? 'hidden lg:block lg:text-normal'
                                                : 'lg:text-3xl'
                                        } mb-2 xs:mb-5 lg:mb-1 group-hover:text-green-hover`}>
                                        {theme === 'slim'
                                            ? 'Click or drag and drop here to select your file to upload.'
                                            : 'Select your file to upload'}
                                    </p>
                                    {theme !== 'slim' && (
                                        <p className="text-lg hidden lg:block">
                                            or drag and drop it here.
                                        </p>
                                    )}
                                </>
                                <p className="text-xs lg:text-sm text-gray-six lg:mt-5">
                                    Supported: PDF, JPG, PNG
                                </p>
                            </motion.div>
                        )}
                        {currentFiles.length > 0 &&
                            !isUploading &&
                            currentFiles.map((currentFile, idx) => (
                                <motion.div
                                    key={`file-info-section-${idx}`}
                                    className={`flex flex-wrap items-center relative ${
                                        isModal ? 'z-60' : 'z-20'
                                    } mt-6 lg:mt-0`}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}>
                                    <FileChosenIcon className="mr-2 flex-none" />
                                    <div className="flex-1 max-w-[calc(100%-101px)]">
                                        {type === 'consumer' ? (
                                            <p className="text-xl whitespace-nowrap overflow-hidden text-ellipsis font-bold font-heading tracking-wider">
                                                File Selected
                                            </p>
                                        ) : (
                                            <>
                                                <p className="whitespace-nowrap overflow-hidden text-ellipsis">
                                                    {currentFile.name}
                                                </p>
                                                <span className="text-gray-six text-sm">
                                                    ({returnFileSize(currentFile.size)})
                                                </span>
                                            </>
                                        )}
                                    </div>
                                    <button
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            removeFile();
                                        }}
                                        className="w-5 h-5 lg:w-8 lg:h-8 absolute right-0 top-1/2 -translate-y-1/2 rounded-full bg-salmon text-white text-center lg:text-xl flex-none">
                                        <IoCloseSharp className="absolute-center pointer-events-none" />
                                    </button>
                                </motion.div>
                            ))}
                        {isUploading && (
                            <motion.div
                                key="file-upload-progress-section"
                                className="text-center relative z-15"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}>
                                <div className="relative block w-[102px] mx-auto">
                                    <UploadSpinner
                                        className="pointer-events-none"
                                        progress={progress}
                                    />
                                    <span className="absolute-center font-heading text-4xl">
                                        {progress.toFixed(1)}%
                                    </span>
                                </div>

                                <p className="text-lg hidden lg:block lg:mb-5">
                                    {uploadError ? (
                                        <span className="text-red font-bold">Error!</span>
                                    ) : uploadSuccess ? (
                                        'Done.'
                                    ) : (
                                        'Uploading file...'
                                    )}
                                </p>

                                {!uploadSuccess && (
                                    <button
                                        className="btn-shell flex items-center lg:py-4 mx-auto mt-4"
                                        onClick={(ev) => {
                                            ev.preventDefault();
                                            removeFile();
                                            setIsUploading(false);
                                            setUploadError(false);
                                        }}>
                                        <span className="text-sm pointer-events-none">Cancel</span>
                                        <IoCloseSharp className="ml-3 pointer-events-none" />
                                    </button>
                                )}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                {!fileType && (
                    <button disabled={true} className="btn-shell w-full mt-4 lg:mt-5" type="submit">
                        File Type Not Selected
                    </button>
                )}
                {currentFiles.length > 0 && !isUploading && fileType && (
                    <button className="btn-primary w-full mt-4 lg:mt-5" type="submit">
                        {uploadButtonOverride
                            ? uploadButtonOverride
                            : 'Submit to GEM SLEEP for review'}
                    </button>
                )}
            </form>
        </>
    );
};

export default UploadFile;
