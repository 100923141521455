import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';

//Components
import CoachingResult from './CoachingResult';
import HasSleepApnea from './HasSleepApnea';
import HighRisk from './HighRisk';
import NoSleepApnea from './NoApnea';
import LowScore from './LowScore';

function AssessmentResult() {
    const posthog = usePostHog();

    const { answers, apneaScore, hasHighRisk } = useSelector((state) => state.assessment);

    const isCoachingPath = answers.howBroughtToGem === 'STRUGGLE_WITH_CPAP';
    const lowScoreAdditions =
        answers.lowScoreAdditions && !answers.lowScoreAdditions?.includes('NONE');

    useEffect(() => {
        if (
            Array.isArray(answers.highRiskSelections) &&
            answers.highRiskSelections.length > 0 &&
            answers.highRiskSelections.indexOf('NONE') < 0 &&
            apneaScore >= 3
        ) {
            posthog?.capture('Received Assessment Result', { result: 'Apnea - high risk' });
            ReactGA.event({
                category: 'User',
                action: 'Got assessment result',
                label: 'Apnea - high risk',
            });
        } else if (apneaScore >= 3) {
            posthog?.capture('Received Assessment Result', { result: 'Apnea' });
            ReactGA.event({
                category: 'User',
                action: 'Got assessment result',
                label: 'Apnea',
            });
        } else if (apneaScore > 0 && lowScoreAdditions) {
            posthog?.capture('Received Assessment Result', { result: 'Low Score' });
            ReactGA.event({
                category: 'User',
                action: 'Got assessment result',
                label: 'Low Score',
            });
        } else {
            posthog?.capture('Received Assessment Result', { result: 'No Apnea' });
            ReactGA.event({
                category: 'User',
                action: 'Got assessment result',
                label: 'No Apnea',
            });
        }
        // eslint-disable-next-line
    }, []);

    // Show one of four outcomes from assessment
    if (hasHighRisk) {
        return <HighRisk />;
    } else if (isCoachingPath) {
        return <CoachingResult />;
    } else if (apneaScore >= 3) {
        return <HasSleepApnea />;
    } else if (apneaScore > 0 && lowScoreAdditions) {
        return <LowScore />;
    } else {
        return <NoSleepApnea />;
    }
}

export default AssessmentResult;
