import { FiChevronRight } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { api, useLogoutMutation } from '../../app/services/auth';
import { toggle, toggleModal } from '../../features/ui/uiSlice';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ReactComponent as EditIcon } from '../../images/icons/edit.svg';
import { formatPhoneNumber } from '../../utils/constants';

const AccountContent = ({ closeHandler }) => {
    const dispatch = useDispatch();
    const [logout] = useLogoutMutation();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);

    return (
        <>
            <h5 className="relative mb-2 xs:mb-6 flex items-center justify-between">
                <span className="font-heading text-4xl font-bold lg:text-5xl">My account</span>
                {user && (
                    <button
                        className="btn-shell flex items-center lg:py-4"
                        onClick={() => dispatch(toggle({ key: 'editScreenOpen' }))}>
                        <span className="text-sm pointer-events-none">Edit</span>
                        <EditIcon className="ml-3 pointer-events-none" />
                    </button>
                )}
                <button
                    onClick={closeHandler}
                    className="flex items-center font-heading text-sm font-bold">
                    <span className="tracking-widest pointer-events-none">close</span>
                    <span className="relative ml-2 aspect-1 w-6 rounded-full border-2 border-green-400 pointer-events-none">
                        <CloseIcon className="absolute-center pointer-events-none" />
                    </span>
                </button>
            </h5>
            <div className="flex flex-1 flex-col justify-between">
                {!user ? (
                    <p className="text-xl font-bold">No user found.</p>
                ) : (
                    <ul className="ph-no-capture space-y-1 xs:space-y-4 lg:space-y-6">
                        <li>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                First Name
                            </span>
                            <p className="font-bold lg:text-lg">{user.firstName}</p>
                        </li>
                        <li>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                Last Name
                            </span>
                            <p className="font-bold lg:text-lg">{user.lastName}</p>
                        </li>
                        <li>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                Email (username)
                            </span>
                            <p className="font-bold lg:text-lg">{user.email}</p>
                        </li>
                        <li>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                Password
                            </span>
                            <p className="font-bold lg:text-lg">•••••••••••</p>
                        </li>
                        <li>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                Phone Number
                            </span>
                            <p className="font-bold lg:text-lg">{formatPhoneNumber(user.phone)}</p>
                        </li>

                        <li>
                            <span className="font-heading text-sm font-bold tracking-widest text-gray-six">
                                Address
                            </span>
                            <p className="font-bold lg:text-lg">
                                {user.street1 ? (
                                    <span>{user.street1}</span>
                                ) : (
                                    <span className="text-gray opacity-30">Street</span>
                                )}
                                {user.street2 && <span>, {user.street2}</span>}
                            </p>
                            <p className="font-bold lg:text-lg">
                                {user.city ? (
                                    <span>{user.city}</span>
                                ) : (
                                    <span className="text-gray opacity-30">City</span>
                                )}
                                , {user.state} {user.zip && user.zip}
                            </p>
                        </li>
                    </ul>
                )}
                <nav className="divide-y divide-gray-light">
                    <button
                        className="items-center flex w-full justify-between py-2 xs:py-5"
                        onClick={() => {
                            dispatch(toggle({ key: 'orderHistoryOpen' }));
                        }}>
                        <span className="font-heading text-sm font-bold tracking-[0.15em] pointer-events-none">
                            Order history
                        </span>
                        <span className="relative h-6 w-6 rounded-full border-2 border-green-400 pointer-events-none">
                            <FiChevronRight className="absolute-center pointer-events-none" />
                        </span>
                    </button>
                    {/* <button
                        className="items-center flex w-full justify-between py-2 xs:py-5 pointer-events-none"
                        onClick={() => {
                            navigate('/dashboard/insurance');
                            closeHandler();
                        }}>
                        <span className="font-heading text-sm font-bold tracking-[0.15em] pointer-events-none">
                            Insurance
                        </span>
                        <span className="relative h-6 w-6 rounded-full border-2 border-green-400 pointer-events-none">
                            <FiChevronRight className="absolute-center pointer-events-none" />
                        </span>
                    </button> */}
                    <button
                        className="items-center flex w-full justify-between py-2 xs:py-5"
                        onClick={() => {
                            dispatch(toggleModal('terms'));
                        }}>
                        <span className="font-heading text-sm font-bold tracking-[0.15em] pointer-events-none">
                            Terms &amp; Conditions
                        </span>
                        <span className="relative h-6 w-6 rounded-full border-2 border-green-400 pointer-events-none">
                            <FiChevronRight className="absolute-center pointer-events-none" />
                        </span>
                    </button>
                    <button
                        className="items-center flex w-full justify-between py-2 xs:py-5"
                        onClick={() => {
                            dispatch(toggleModal('informedConsent'));
                        }}>
                        <span className="font-heading text-sm font-bold tracking-[0.15em] pointer-events-none">
                            Consent information
                        </span>
                        <span className="relative h-6 w-6 rounded-full border-2 border-green-400 pointer-events-none">
                            <FiChevronRight className="absolute-center pointer-events-none" />
                        </span>
                    </button>
                </nav>
                {user && (
                    <button
                        className="btn-primary w-full mt-2 xs:mt-4 mb-20"
                        onClick={async () => {
                            navigate('/sign-in');
                            logout(null).then(() => {
                                localStorage.removeItem('_gh_user_logged_in');
                                dispatch(api.util.resetApiState());
                                dispatch(toggle({ key: 'accountOpen', override: false }));
                            });
                        }}>
                        Sign Out
                    </button>
                )}
            </div>
        </>
    );
};

export default AccountContent;
