import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Modal } from '..';
import { toggleModal } from '../../features/ui/uiSlice';
import { useGetWebflowTermsQuery } from '../../app/services/webflow';

function UnauthenticatedModals() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { modalsOpen } = useSelector((state) => state.ui);
    const [consent, setConsent] = useState(undefined);

    const { data: webflowTerms, isLoading } = useGetWebflowTermsQuery();

    useEffect(() => {
        if (isLoading) return;
        setConsent(webflowTerms?.items?.find((t) => t.slug === 'consent'));
    }, [webflowTerms, isLoading]);

    useEffect(() => {
        if (searchParams.entries().length === 0) return;
        if (searchParams.get('fromEmailVerification')) dispatch(toggleModal('emailVerified'));
        if (searchParams.get('privacyPolicy') === 'show') dispatch(toggleModal('privacy'));
        if (searchParams.get('terms') === 'show') dispatch(toggleModal('terms'));
        if (searchParams.get('notice') === 'show') dispatch(toggleModal('notice'));
        if (searchParams.get('informedConsent') === 'show')
            dispatch(toggleModal('informedConsent'));
        if (searchParams.get('marketing') === 'show') dispatch(toggleModal('marketing'));
    }, [dispatch, searchParams]);

    return (
        <>
            {/* Email Verification Modal shown through SignIn or search params*/}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('emailVerified') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('emailVerified'));
                }}>
                <div className="space-y-4 text-gray-darker">
                    <h3 className="mb-4 font-heading text-3xl lg:text-5xl">
                        Your email is confirmed. Sign in to continue.
                    </h3>
                    <button
                        className="btn-primary w-full"
                        onClick={() => {
                            dispatch(toggleModal('emailVerified'));
                            navigate('/sign-in');
                        }}>
                        Continue
                    </button>
                </div>
            </Modal>

            {/* Contact Modal shown in unauthenticated Footer, Nav, OralTherapyTreatment, SleepStudyResult, ComparisonCharts, or Treatment homepage*/}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('contact') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('contact'));
                }}>
                <h3 className="mb-4 font-heading text-2xl">Contact Us</h3>
                <p className="mb-2">
                    <a href="tel:+18339464436" className="border-b-2 border-b-green-400 font-bold">
                        1-833-946-4GEM
                    </a>
                </p>
                <p>
                    <a
                        href="mailto:info@gem.health"
                        className="border-b-2 border-b-green-400 font-bold">
                        info@gem.health
                    </a>
                </p>
            </Modal>

            {/* Notice Modal shown in CreateAccountForm or through searchParams */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('notice') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('notice'));
                }}>
                <div className="space-y-4 text-sm">
                    <p>Effective Date July 1, 2022</p>
                    <h3 className="mb-4 font-heading text-2xl">NOTICE OF PRIVACY PRACTICES</h3>
                    <p>
                        <strong>
                            <em>
                                THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED
                                AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS
                                INFORMATION.&nbsp;&nbsp;PLEASE REVIEW IT CAREFULLY.
                            </em>
                        </strong>
                    </p>
                    <p>
                        <strong>Our Duties</strong>
                    </p>
                    <p>We are required by law:</p>
                    <p>to maintain the privacy of your medical information,&nbsp;</p>
                    <p>
                        to give you this Notice describing our legal duties and privacy practices,
                        and&nbsp;
                    </p>
                    <p>to follow the terms of the Notice currently in effect. &nbsp;</p>
                    <p>
                        <strong>How We May Use And Disclose Medical Information About You</strong>
                    </p>
                    <p>
                        In accordance with Federal law, we will not use or disclose your medical
                        information without your authorization, except as described in this Notice.
                    </p>
                    <p>
                        We will use your medical information for <strong>Treatment</strong>. &nbsp;
                    </p>
                    <p>
                        For example: Information obtained by a nurse, physician, or other member of
                        your healthcare team will be recorded in your record and used to determine
                        the course of treatment that should work best for you.&nbsp; &nbsp;
                    </p>
                    <p>
                        We may also provide your subsequent healthcare provider with copies of
                        reports to assist him or her in treating you.&nbsp;
                    </p>
                    <p>
                        We will use your medical information for <strong>Payment</strong>. &nbsp;
                    </p>
                    <p>
                        For<strong>&nbsp;</strong>example: A bill may be sent to you or a
                        third-party payer.&nbsp;&nbsp;The information on or accompanying the bill
                        may include information that identifies you, as well as your diagnosis,
                        procedures, and supplies used.&nbsp;
                    </p>
                    <p>
                        We will use your medical information for{' '}
                        <strong>Health Care Operations</strong>. &nbsp;
                    </p>
                    <p>
                        For<strong>&nbsp;</strong>example: We may use information in your health
                        record to assess the care and outcomes in your case and others like
                        it.&nbsp;&nbsp;This information will then be used in an effort to improve
                        the quality and effectiveness of the healthcare and services we
                        provide.&nbsp;
                    </p>
                    <p>
                        <em>Business Associates</em>: There are some services provided in our
                        organization through contracts with business associates, such as our
                        clinical practice manager and website platform provider, Gem Specialty
                        Health, Inc. &nbsp;&nbsp;We may disclose your health information to our
                        business associates so they can perform the job we&rsquo;ve asked them to
                        do.&nbsp;&nbsp;However, we require our business associates to take
                        precautions to protect your medical information. &nbsp;
                    </p>
                    <p>
                        <em>Notification of Family</em>: We may use or disclose information to
                        notify or assist in notifying a family member, personal representative, or
                        other person responsible for your care of your location and general
                        condition. &nbsp;
                    </p>
                    <p>
                        <em>Communication With Family</em>: Health professionals, using their best
                        judgment, may disclose to a family member, other relative, close personal
                        friend or any other person you identify, health information relevant to that
                        person&rsquo;s involvement in your care. &nbsp;
                    </p>
                    <p>
                        <em>Research</em>: We may disclose information to researchers when their
                        research has been approved by an institutional review board that has
                        reviewed the research proposal and established protocols to ensure the
                        privacy of your medical information. &nbsp; &nbsp;
                    </p>
                    <p>
                        <em>Funeral Director, Coroner, and Medical Examiner</em>: Consistent with
                        applicable law we may disclose health information to funeral directors,
                        coroners, and medical examiners to help them carry out their duties. &nbsp;
                    </p>
                    <p>
                        <em>Organ Procurement Organizations</em>: Consistent with applicable law, we
                        may disclose health information to organ procurement organizations or other
                        entities engaged in the procurement, banking, or transplantation of organs
                        for the purpose of tissue donation and transplant. &nbsp;
                    </p>
                    <p>
                        <em>Food and Drug Administration (FDA)</em>: We may disclose to the FDA
                        health information relative to adverse events, product defects, or post
                        marketing surveillance information to enable product recalls, repairs, or
                        replacement. &nbsp;
                    </p>
                    <p>
                        <em>Public Health</em>: As required by law, we may disclose your health
                        information to public health or legal authorities charged with preventing or
                        controlling disease, injury, or disability, including child abuse and
                        neglect. &nbsp;
                    </p>
                    <p>
                        <em>Victims of Abuse, Neglect or Domestic Violence</em>: We may disclose to
                        appropriate governmental agencies, such as adult protective or social
                        services agencies, your health information, if we reasonably believe you are
                        a victim of abuse, neglect, or domestic violence. &nbsp;
                    </p>
                    <p>
                        <em>Health Oversight</em>: In order to oversee the health care system,
                        government benefits programs, entities subject to governmental regulation
                        and civil rights laws for which health information is necessary to determine
                        compliance, we may disclose health information for oversight activities
                        authorized by law, such as audits and civil, administrative, or criminal
                        investigations. &nbsp;
                    </p>
                    <p>
                        <em>Court Proceeding</em>: We may disclose health information in response to
                        requests made during judicial and administrative proceedings, such as court
                        orders or subpoenas. &nbsp;
                    </p>
                    <p>
                        <em>Law Enforcement</em>: Under certain circumstances, we may disclose
                        health information to law enforcement officials.&nbsp;&nbsp;These
                        circumstances include reporting required by certain laws (such as the
                        reporting of certain types of wounds), pursuant to certain subpoenas or
                        court orders, reporting limited information concerning identification and
                        location at the request of a law enforcement official, reports regarding
                        suspected victims of crimes at the request of a law enforcement official,
                        reporting death, crimes on our premises, and crimes in emergencies. &nbsp;
                    </p>
                    <p>
                        <em>Inmates</em>:&nbsp;&nbsp;If you are an inmate of a correctional
                        institution or under the custody of a law enforcement official, we may
                        release medical information about you to the correctional institution or law
                        enforcement official.&nbsp;&nbsp;This release would be necessary (1) for the
                        institution to provide you with health care; (2) to protect your health and
                        safety or the health and safety of others; or (3) for the safety and
                        security of the correctional institution.
                    </p>
                    <p>
                        <em>Threats to Public Health or Safety</em>: We may disclose or use health
                        information when it is our good faith belief, consistent with ethical and
                        legal standards, that it is necessary to prevent or lessen a serious and
                        imminent threat or is necessary to identify or apprehend an individual.
                        &nbsp;
                    </p>
                    <p>
                        <em>Specialized Government Functions</em>: Subject to certain requirements,
                        we may disclose or use health information for military personnel and
                        veterans, for national security and intelligence activities, for protective
                        services for the President and others, for medical suitability
                        determinations for the Department of State, for correctional institutions
                        and other law enforcement custodial situations, and for government programs
                        providing public benefits. &nbsp;
                    </p>
                    <p>
                        <em>Workers Compensation</em>: We may disclose health information when
                        authorized and necessary to comply with laws relating to workers
                        compensation or other similar programs. &nbsp;
                    </p>
                    <p>
                        <strong>Other Uses</strong>
                    </p>
                    <p>
                        We may also use and disclose your personal health information for the
                        following purposes:&nbsp;
                    </p>
                    <p>to contact you to remind you of an appointment for treatment,&nbsp;</p>
                    <p>to describe or recommend treatment alternatives to you, or</p>
                    <p>
                        to furnish information about health-related benefits and services that may
                        be of interest to you.
                    </p>
                    <p>
                        All other uses and disclosures of your medical information will be made only
                        with your written permission.&nbsp;&nbsp;Once given, you may revoke the
                        authorization by writing to us at&nbsp;
                    </p>
                    <p>&nbsp; &nbsp;&nbsp;</p>
                    <p>GEM Health&nbsp;</p>
                    <p>Attn: Privacy Officer&nbsp;</p>
                    <p>
                        2214 5<sup>th</sup> Street STE 5
                    </p>
                    <p>White Bear Lake, MN 55110 &nbsp; &nbsp;</p>
                    <p>&nbsp; &nbsp;&nbsp;</p>
                    <p>
                        You understand that we are unable to take back any disclosure we have
                        already made with your permission.
                    </p>
                    <p>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
                    <p>
                        <strong>Individual Rights</strong>
                    </p>
                    <p>
                        You have many rights concerning the confidentiality of your medical
                        information.&nbsp;&nbsp;You have the right:
                    </p>
                    <p>
                        to request restrictions on the medical information we may use and disclose
                        for treatment, payment, and health care operations.&nbsp;&nbsp;
                        <strong>We are not required to agree to these requests.</strong> &nbsp;To
                        request restrictions, please send a written request to the address below.
                    </p>
                    <p>
                        to receive confidential communications of medical information about you in a
                        certain manner or at a certain location.&nbsp;&nbsp;For instance, you may
                        request that we only contact you at work or by mail.&nbsp;&nbsp;To make such
                        a request, you must write to us at the address below, and tell us how or
                        where you wish to be contacted.
                    </p>
                    <p>
                        to inspect or copy your medical information.&nbsp;&nbsp;You must submit your
                        request in writing to the address below.&nbsp;&nbsp;If you request a copy of
                        your medical information we may charge you a fee for the cost of copying,
                        mailing or other supplies.&nbsp;&nbsp;In certain circumstances we may deny
                        your request to inspect or copy your medical information.&nbsp;&nbsp;If you
                        are denied access to your medical information, you may request that the
                        denial be reviewed.&nbsp;&nbsp;Another licensed health care professional
                        will then review your request and the denial.&nbsp;&nbsp;The person
                        conducting the review will not be the person who denied your
                        request.&nbsp;&nbsp;We will comply with the outcome of the review.
                    </p>
                    <p>
                        to amend medical information.&nbsp;&nbsp;If you feel that medical
                        information we have about you is incorrect or incomplete, you may ask us to
                        amend the information.&nbsp;&nbsp;To request an amendment, you must write to
                        us at the address below.&nbsp;&nbsp;You must also give us a reason to
                        support your request.&nbsp;&nbsp;We may deny your request to amend your
                        medical information if it is not in writing or does not provide a reason to
                        support your request.&nbsp;&nbsp;We may also deny your request if:
                    </p>
                    <ul className="ml-4 list-disc">
                        <li>
                            the information was not created by us, unless the person that created
                            the information is no longer available to make the amendment,
                        </li>
                        <li>
                            the information is not part of the medical information kept by or for
                            us,
                        </li>
                        <li>
                            is not part of the information you would be permitted to inspect or
                            copy, or
                        </li>
                        <li>is accurate and complete</li>
                    </ul>
                    <p>
                        to receive an accounting of disclosures of your medical
                        information.&nbsp;&nbsp;You must submit a request in writing to the address
                        below.&nbsp;&nbsp;Not all medical information is subject to this
                        request.&nbsp;&nbsp;Your request must state a time period, no longer than 6
                        years and may not include dates before April 24, 2003.&nbsp;&nbsp;Your
                        request must state how you would like to receive the report (paper,
                        electronically).&nbsp;&nbsp;The first list you request within a 12 month
                        period is free.&nbsp;&nbsp;For additional lists, we may charge you the cost
                        of providing the list.&nbsp;&nbsp;We will notify you of this cost and you
                        may choose to withdraw or modify your request before charges are incurred.
                    </p>
                    <p>
                        to receive a paper copy of this Notice upon request, even if you have agreed
                        to receive the Notice electronically.&nbsp;&nbsp;You may obtain a copy of
                        this notice at our website, www.gem.health.&nbsp;&nbsp;You must submit a
                        request for a paper notice in writing to the address below.
                    </p>
                    <p>
                        <strong>All</strong> requests to restrict use of your medical information
                        for treatment, payment, and health care operations, to inspect and copy
                        medical information, to amend your medical information, or to receive an
                        accounting of disclosures of medical information must be made in writing to
                        the following address:
                    </p>
                    <p>GEM Health&nbsp;</p>
                    <p>Attn: Privacy Officer&nbsp;</p>
                    <p>
                        2214 5<sup>th</sup> Street STE 5
                    </p>
                    <p>White Bear Lake, MN 55110 &nbsp; &nbsp;</p>
                    <p>
                        <strong>Complaints</strong>
                    </p>
                    <p>
                        If you believe that your privacy rights have been violated, a complaint may
                        be made to our Privacy Officer at the following email:
                        info@gem.health.&nbsp;&nbsp;You may also submit a complaint to the Secretary
                        of the Department of Health and Human Services. &nbsp;
                    </p>
                    <p>
                        <strong>
                            You will not be penalized in any way for filing a complaint. &nbsp;
                        </strong>
                    </p>
                    <p>All complaints should be sent in writing to the following address:</p>
                    <p>GEM Health&nbsp;</p>
                    <p>Attn: Privacy Officer&nbsp;</p>
                    <p>
                        2214 5<sup>th</sup> Street STE 5
                    </p>
                    <p>White Bear Lake, MN 55110</p>
                    <p>&nbsp; &nbsp;&nbsp;</p>
                    <p>
                        <strong>Changes to This Notice</strong>
                    </p>
                    <p>
                        We reserve the right to change our privacy practices and to apply the
                        revised practices to medical information about you that we already
                        have.&nbsp;&nbsp;Any revision to our privacy practices will be described in
                        a revised Notice that will be posted prominently on our website.
                    </p>
                </div>
            </Modal>

            {/* Privacy Modal shown in unauthenticated Footer, Nav, or search params*/}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('privacy') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('privacy'));
                }}>
                <h3 className="mb-5 font-heading text-2xl">Privacy Policy</h3>
                <div className="space-y-4 text-sm">
                    <p>
                        <strong>Online Services Privacy Policy</strong>
                    </p>
                    <p>
                        ATTENTION: PLEASE READ THIS PRIVACY POLICY AND OUR TERMS OF SERVICE
                        CAREFULLY BEFORE USING THE ONLINE SERVICES.&nbsp;&nbsp;ACCESSING ANY PART OF
                        THE ONLINE SERVICES INDICATES THAT YOU ACCEPT THIS PRIVACY POLICY IN FULL.
                        IF YOU DO NOT ACCEPT THIS PRIVACY POLICY, DO NOT USE THE ONLINE SERVICES AND
                        LEAVE THE ONLINE SERVICES IMMEDIATELY. &nbsp;
                    </p>
                    <p>
                        This Privacy Policy applies to the online, mobile website, platforms,
                        services and applications that we own or operate and that contain a link to
                        this Privacy Policy (collectively, the &ldquo;Online
                        Services&rdquo;).&nbsp;&nbsp;This Privacy Policy describes the information
                        we collect on the Online Services and how Gem Specialty Health, Inc.
                        (&ldquo;Gem Health,&rdquo; &ldquo;we,&rdquo; &ldquo;our&rdquo; or
                        &ldquo;us&rdquo;) uses it, the choices you can make about your personal
                        information and how we protect your personal information.&nbsp;
                    </p>
                    <p>
                        This Privacy Policy was last updated on July 1, 2022. We may change this
                        Privacy Policy as well as the content of the Online Services at any time.
                        Please review the Privacy Policy each time you visit the Online
                        Services.&nbsp;&nbsp;BY USING THE ONLINE SERVICES, YOU AGREE TO BE BOUND BY
                        THE MOST RECENT VERSION OF THE PRIVACY POLICY.
                    </p>
                    <p>
                        <strong>INFORMATION THAT WE COLLECT AND HOW WE USE IT</strong>
                    </p>
                    <p>
                        We collect your name, your address, your phone number, your email address,
                        health information about you, your medical history and past treatments, and
                        other health information when you register, request materials, or complete
                        contact forms on the Online Services. This information includes individually
                        identifiable information.&nbsp;&nbsp;We use this information to identify
                        providers or health services that you may wish to utilize, send you the
                        information and to communicate with you and the providers and health
                        services we identify for you. You also may submit comments on certain
                        portions of the Online Services or through the contact mechanism on the
                        Online Services. We generally do not encourage the submission of any
                        personal information in these comments or through the contact mechanism
                        other than what we expressly require (e.g., name and email address for the
                        contact mechanism). Information you disclose in the comments becomes public
                        information and you should exercise caution when deciding to disclose your
                        information in a submission.
                    </p>
                    <p>
                        Your medical data will never be shared on your behalf beside specifically in
                        order for you to receive the treatment desired through Gem Health and as set
                        forth in the Disclosure of Information section below. &nbsp;
                    </p>
                    <p>
                        We also automatically collect certain technical information about your visit
                        to the Online Services, including: (i) the Internet domain from which you
                        access the Online Services (for example, &ldquo;xcompany.com&rdquo;,
                        &ldquo;yschool.edu&rdquo; or &ldquo;zorganization.org&rdquo;, etc.), (ii)
                        the Internet Protocol address (a unique number for each computer connected
                        to the Internet) from which you access the Online Services, (iii) the type
                        of Web browser (for example, Internet Explorer, Mozilla Firefox, Google
                        Chrome, Microsoft Edge, etc.) used to access the Online Services, (iv) the
                        operating system (for example, Windows and Unix, etc.) used to access the
                        Online Services, (v) the date and time you access the Online Services, (vi)
                        the URLs of the Web pages you visit, and (vii) the URL of the forwarding
                        website if you visited the Online Services from another website or search
                        engine. With this information, we learn about the number of visitors to
                        different sections of the Online Services, find out what information is the
                        most viewed and the types of technology the visitors use. We use this
                        information to help make the Online Services more useful for visitors.&nbsp;
                    </p>
                    <p>
                        We may enter into business associate agreements with providers and health
                        plans or health insurers, including, employer-sponsored health plans
                        (&ldquo;Health Insurers&rdquo;), to make our Online Services available to
                        patients of such providers or enrollees, members, or participants of such
                        Health Insurers (&ldquo;Partners&rdquo;). When you use our Online Services,
                        we may collect Information about you, directly or indirectly, from or
                        through applicable Partners. We will comply with applicable business
                        associate agreements, this Privacy Policy, and the Health Insurance
                        Portability and Accountability of 1996, as amended, and its implementing
                        regulations (collectively, &ldquo;HIPAA&rdquo;) with respect to your
                        information that is protected health information, as defined under HIPAA.
                        This Privacy Policy is separate from applicable Partners&rsquo; HIPAA
                        notices of privacy practices. Your provider or Health Insurer&rsquo;s notice
                        of privacy practices describes in detail how it uses and discloses your
                        protected health information. Please contact your provider or Health Insurer
                        for a copy of their HIPAA Notice of Privacy Practices.
                    </p>
                    <p>
                        Our Online Services operate on computer servers in the United States;
                        therefore, any information you provide will be processed by a computer
                        server located within the United States.
                    </p>
                    <p>
                        <strong>DISCLOSURE OF INFORMATION</strong>
                    </p>
                    <p>
                        We will not voluntarily share your name and email provided via the Online
                        Services with any third party, except:
                    </p>
                    <ul className="ml-4 list-disc space-y-2">
                        <li>with the providers that we have identified for you;</li>
                        <li>
                            with third parties that we hire to perform services for us (these third
                            parties are not authorized to keep or use your personal information for
                            any other purpose);
                        </li>
                        <li>
                            to comply with legal requirements, such as law, regulation, warrant,
                            subpoena, court order, or with a regulator or law enforcement agency or
                            personnel;
                        </li>
                        <li>
                            with third parties to enforce Terms of Service, including investigation
                            of potential violations;
                        </li>
                        <li>
                            with third parties to protect against harm to the rights, property or
                            safety of our users or the public as required or permitted by law; or
                        </li>
                        <li>
                            in connection with a corporate sale, merger, reorganization,
                            acquisition, dissolution or similar event.
                        </li>
                    </ul>
                    <p>
                        We will not sell your medical data or personal information to any third
                        party.
                    </p>
                    <p>
                        <strong>YOUR CHOICES AND ACCESS</strong>
                    </p>
                    <p>
                        We want to keep your name and email address accurate.&nbsp;&nbsp;You can
                        correct or update your name or email address that you have provided
                        us.&nbsp;&nbsp;You may contact us at admin@gem.health to request such
                        change.
                    </p>
                    <p>
                        <strong>LINKS TO OTHER WEBSITES</strong>
                    </p>
                    <p>
                        We do not endorse and are not responsible for the information practices or
                        privacy policies of websites operated by others that may be linked to or
                        from the Online Services.&nbsp;&nbsp;If you decide to access a third
                        party&rsquo;s website that may be linked to or from the Online Services, you
                        should consult that website&rsquo;s privacy policy.
                    </p>
                    <p>
                        <strong>COOKIES</strong>
                    </p>
                    <p>
                        A cookie is a small file that a website transfers to your computer to allow
                        it to remember specific information about your session while you are
                        connected. There are two types of cookies, session and persistent. Session
                        cookies last only as long as your web browser is open. Once you close your
                        web browser, the cookie disappears. We may use session cookies for technical
                        purposes such as to enable better navigation through the Online Services or
                        to allow you to customize your preferences for interacting with the Online
                        Services. Persistent cookies, which store information on your computer for
                        longer periods of time, are utilized for registered users of the Online
                        Services. If you do not wish to have cookies stored on your machine, you can
                        turn them off in your web browser. However, this may affect the functioning
                        of some sections of the Online Services.
                    </p>
                    <p>
                        We also use a cookie from a third-party that collects anonymous traffic data
                        to measure usage of the Online Services and to maintain the Online Services.
                        We employ Google Analytics, which uses
                        <a
                            href="http://www.google.com/policies/privacy/cookies"
                            target="_blank"
                            rel="noreferrer">
                            http://www.google.com/policies/privacy/cookies
                        </a>{' '}
                        to track visitor interactions with the Online Services and to store
                        information.
                    </p>
                    <p>
                        <strong>WEB BEACONS</strong>
                    </p>
                    <p>
                        In addition to cookies, Gem Health&rsquo;s Online Services may use other
                        technologies such as pixel tags and web beacons. These technologies help us
                        to better understand customer behavior including for security and fraud
                        prevention purposes, tell us which parts of our websites people have
                        visited, and facilitate and measure the effectiveness of advertisements and
                        web searches, as well as improve our marketing and advertising efforts.
                    </p>
                    <p>
                        <strong>SECURITY</strong>
                    </p>
                    <p>
                        We maintain administrative, technical, and physical safeguards designed to
                        protect the information that you provide on our Online Services. These
                        safeguards vary based on the sensitivity of the information that is being
                        collected, used and stored. However, no security system is impenetrable and
                        we cannot guarantee the security of our Online Services, nor can we
                        guarantee the security of the information you transmit to us over the
                        Internet, including your use of e-mail. We are not liable for the illegal
                        acts of third parties such as criminal hackers.
                    </p>
                    <p>
                        It is your responsibility to safeguard the devices you use to access our
                        Online Services (such as laptops, tablets and mobile devices), and to use
                        appropriate security settings on those devices. If those devices are lost,
                        stolen or misplaced, others may be able to access your account and your
                        personal Information using those devices. If you log into the Online
                        Services using a public computer or device, or the computer or device of
                        another person, you should affirmatively log out of your account (i) prior
                        to ending your session, or (ii) if you will be inactive on the Online
                        Services for more than a few minutes otherwise, the next user of that
                        computer or device may be able to access your account and the Information in
                        your account if your session has not ended.
                    </p>
                    <p>
                        You agree that we are not responsible for any harm that may result from
                        someone accessing your account or personal Information on a lost, stolen or
                        misplaced device or on a public computer or kiosk where you do not for any
                        reason take the necessary steps to log out of your account prior to ending a
                        session on such public computer or kiosk.
                    </p>
                    <p>
                        We offer mobile applications that enable us to communicate with you through
                        push notifications. You may be able to turn off push notifications in your
                        mobile phone settings. You may also be able to control preview settings in
                        your e-mail applications. Please note, choosing to disable certain
                        functionality of the mobile application may impact the functionality of
                        other areas of the mobile application.
                    </p>
                    <p>
                        We retain information for as long as necessary for the purpose for which it
                        is collected, subject to a longer period if the information is relevant to a
                        legal challenge.
                    </p>
                    <p>
                        <strong>CHILDREN</strong>
                    </p>
                    <p>
                        The Online Services are not available or intended for users under the age of
                        18. We will not knowingly collect personal information from users under the
                        age of 18. If we discover that a user under the age of 18 has provided us
                        with personal information, we will use reasonable efforts to delete this
                        information. If you have reason to believe we may have accidentally received
                        personal information from a user under the age of 18, please immediately
                        contact us at{' '}
                        <a
                            href="mailto:info@gem.health"
                            className="border-b-2 border-b-green-400 font-bold">
                            info@gem.health
                        </a>
                        .
                    </p>
                    <p>
                        <strong>CONTACT US</strong>
                    </p>
                    <p>
                        If you have any questions about this Privacy Policy or any questions or
                        concerns regarding your privacy, please contact us at{' '}
                        <a
                            href="mailto:info@gem.health"
                            className="border-b-2 border-b-green-400 font-bold">
                            info@gem.health
                        </a>
                        .
                    </p>
                    <p>
                        <strong>CHANGES TO THIS PRIVACY POLICY</strong>
                    </p>
                    <p>
                        We may modify, alter or update this Privacy Policy at any time, so we
                        encourage you to review this Privacy Policy frequently. We will not provide
                        individual notice to you of changes to this Privacy Policy, but when we make
                        updates to this Privacy Policy, we will update the date in this section. We
                        appreciate your interest and hope that you enjoy visiting the Online
                        Services.
                    </p>
                    <p>
                        This Privacy Policy was last updated on July 1, 2022<strong>.</strong>&nbsp;
                    </p>
                </div>
            </Modal>

            {/* Terms Modal shown in unauthenticated Footer, Nav, Profile, or search params */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('terms') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('terms'));
                }}>
                <h3 className="mb-5 font-heading text-2xl">Terms and Conditions</h3>
                <div className="space-y-4 text-sm">
                    <p>
                        <strong>TERMS AND CONDITIONS OF USE</strong>
                    </p>
                    <p>
                        Your use of this website is subject to the following terms and conditions
                        (&quot;Terms and Conditions&quot;), which may be updated without further
                        notice. Please review these Terms and Conditions carefully since they
                        contain important information regarding your use of the website. By using or
                        visiting this website and related services, (&ldquo;Online Services&rdquo;),
                        you indicate your agreement to these Terms and Conditions and the Privacy
                        Policy (incorporated herein by reference). Please discontinue your use of
                        the Online Services in the event that you do not agree to any of the terms
                        of these Terms and Conditions and the Privacy Policy.&nbsp;
                    </p>
                    <p>
                        <strong>The Services We Will Provide to You</strong>
                    </p>
                    <p>
                        Through this website, Gem Specialty Health, Inc. (&ldquo;we,&rdquo;
                        &ldquo;our&rdquo; or &ldquo;us&rdquo;) will provide you with a variety of
                        services, including information regarding health-related resources. In the
                        future, we may choose to offer other services that we determine are
                        appropriate, suitable, and desirable. Please note that some services on the
                        Online Services are available only to our members with a valid user ID and
                        password. Although we will try to provide to you the most comprehensive
                        services possible, we do not guarantee that any one or all of these will be
                        available to you at any particular time. Please note, however, that all
                        services we do provide to you through the Online Services are subject to all
                        of the Terms and Conditions contained herein.
                    </p>
                    <p>
                        Gem Specialty Health, Inc. is not itself a health care provider. Our role is
                        limited to making certain telehealth related information available to you
                        and/or facilitate your access to telemedicine and certain expert medical
                        services. We are independent from the healthcare providers who provide
                        telemedicine services and we are not responsible for such healthcare
                        providers&apos; acts, omissions or for any content of the communications
                        made by them. Gem Specialty Health, Inc. does not engage in the practice of
                        medicine.
                    </p>
                    <p>
                        The Online Services do not provide medical advice and do not create a
                        healthcare provider/patient relationship between you and Gem Specialty
                        Health, Inc.&nbsp;&nbsp;Any services provided by or content accessed from
                        the Websites are for informational purposes only and are not intended to
                        replace the advice of your healthcare provider. Please consult your doctor
                        or other qualified healthcare provider if you have any questions about a
                        medical condition. Call 911 or Your doctor for all medical emergencies. GEM
                        SPECIALTY HEALTH IS NOT RESPONSIBLE OR LIABLE FOR ANY ADVICE, COURSE OF
                        TREATMENT, DIAGNOSIS OR ANY OTHER INFORMATION, SERVICES OR PRODUCTS THAT YOU
                        MAY OBTAIN THROUGH THE WEBSITES
                    </p>
                    <p>
                        <strong>Your Conduct</strong>
                    </p>
                    <p>
                        We grant you permission to access and use the Online Services as set forth
                        in these Terms and Conditions, provided that you do not use or attempt to
                        use us or our Online Services in any manner inconsistent with or that would
                        violate these Terms and Conditions, or any other policy or agreement
                        referenced herein, as applicable. In using&nbsp;&nbsp;the Online Services,
                        your conduct must at all times comply with all relevant laws, rules, and
                        regulations, including all Federal laws and the State, and local laws of the
                        jurisdiction in which you receive such services. In addition, you agree to
                        the following:
                    </p>
                    <ul>
                        <li>
                            You will not use the Online Services to threaten, intimidate, or harass
                            any other person;
                        </li>
                        <li>
                            You will not transmit any pornographic, lewd, immoral, obscene,
                            offensive, libelous, vulgar, or tortious material, material that is
                            invasive of another&apos;s privacy, or material that disparages or
                            insults any individual or group, based on race, ethnicity, gender,
                            nationality, sexual orientation, disability, or other immutable
                            characteristic;
                        </li>
                        <li>
                            You will not transmit false information through the Online Services;
                        </li>
                        <li>
                            You will not upload, post, e-mail or otherwise transmit any material
                            that contains viruses or any other computer code, files or programs
                            designed to interrupt, destroy or limit the functionality of any
                            computer software, hardware or telecommunications equipment;
                        </li>
                        <li>
                            You will not upload, post, e-mail or otherwise transmit any information
                            or content unless you have the lawful right to disseminate such content;
                        </li>
                        <li>
                            You agree that your use of the Online Services will not violate any
                            contractual or fiduciary obligations you have to third parties,
                            including that you will not transmit the private, confidential, trade
                            secret, trade mark, proprietary or other non-public information of any
                            other party through the Online Services;
                        </li>
                        <li>
                            You will not upload, post, e-mail or otherwise transmit any advertising,
                            promotional materials, solicitations, junk mail, &quot;spam,&quot; or
                            any other form of solicitation to any party unless you are expressly
                            authorized to do so by us;
                        </li>
                        <li>
                            You will not otherwise take any steps to interfere with the
                            functionality of the Online Services or of the hardware or software of
                            any other party; and
                        </li>
                        <li>
                            You will not upload, post, store, e-mail or otherwise transmit any
                            material that invades anyone&apos;s privacy, or illustrates, references
                            or encourages conduct that would constitute a criminal offense, give
                            rise to civil liability, or that otherwise violates any local, state,
                            federal, national or international law or regulation, including, but not
                            limited to, the Health Insurance Portability and Accountability Act of
                            1996, including all implementing regulations and all amendments.
                        </li>
                    </ul>
                    <p>
                        <strong>You are Solely Responsible for Your Own Conduct</strong>
                    </p>
                    <p>
                        You are solely responsible for your own conduct on the Online Services, and
                        you will be solely responsible for any violations of these Terms and
                        Conditions. You agree that you will indemnify and hold harmless Gem
                        Specialty Health, Inc., its agents, officers, affiliates, and any third
                        party for any liabilities, costs, damages, and/or expenses that they incur
                        as a result of your actions.
                    </p>
                    <p>
                        <strong>Content on the Website</strong>
                    </p>
                    <p>
                        The Online Services may contain content (&quot;Content&quot;) that is
                        provided by other parties. We will work with the other parties to provide
                        the most accurate information that we can provide. We may, but shall not be
                        obligated to, monitor Content to screen out any information that may be
                        offensive or objectionable. As we indicate below, we do not provide any
                        warranties with respect to the Content and your use of the Online Services
                        is solely at your own risk. We shall not be responsible to you or to any
                        other party for any costs, damages, expenses, or liabilities arising from
                        the acts of any third party, including without limitation vendors and other
                        users that may provide Content that appears on websites referred to on the
                        Online Services. We strongly recommend that you review the user policies,
                        privacy policy and other terms and conditions of all third parties on other
                        websites, including websites to which you may be linked by using the Online
                        Services. We may post or remove any Content at any time, at its sole
                        discretion.
                    </p>
                    <p>
                        We reserve the right (but have no obligation) to review any Content, and to
                        investigate and/or take appropriate action against you in our sole
                        discretion if you violate these Terms &amp; Conditions or otherwise create
                        liability for us or any other person. Such action may include removing or
                        modifying your Content, terminating your access to or use of the website
                        and/or Online Services made available on the website, and/or reporting you
                        to law enforcement authorities.
                    </p>
                    <p>
                        <strong>We are Not Responsible for the Acts of Other Users</strong>
                    </p>
                    <p>
                        We will take precautions to make your experience on the Online Services as
                        pleasant and productive as possible and to make sure that other users follow
                        our policies. However, we is not responsible to you for the conduct of
                        others. Use of this website is solely at your own risk. You agree that in
                        consideration for your use of this website, which is being provided to you
                        free of charge, neither Gem Specialty Health, Inc. , its officers,
                        directors, employees, agents or affiliated corporations will be liable to
                        you for any costs, expenses, liabilities, or damages you may incur as a
                        result of your use of this website.
                    </p>
                    <p>
                        <strong>Third Party Content; Clinical and Medical Content</strong>
                    </p>
                    <p>
                        The website may contain hyperlinks, advertisements or content related to
                        third party web sites, products, including third party products, services or
                        businesses. We may even redirect you automatically to third party web sites
                        or services and your usage of such will be governed, pending your
                        acceptance, by their additional terms and conditions or policies, if
                        applicable. You agree that we is not responsible, nor shall we be liable,
                        for verifying the validity or accuracy of any third party material, content,
                        products, including third party products, advertisements, web sites,
                        services or other related materials. In addition, we do not warrant, will
                        not be responsible for examining or evaluating the accuracy of, and will not
                        have any liability or responsibility for any medical information, research
                        or clinical materials or information provided by any third party material,
                        content, products, advertisements, web sites, services or other materials or
                        documentation. Any medical content, services, information, advice or
                        materials of any kind accessed through the Online Services is for general
                        information or reference purposes only and shall not be relied upon or
                        replace any professional, clinical or medical advice or judgment provided by
                        a physician or other health care professional. You agree that you will not
                        use any third party materials or any services in a manner that would
                        infringe upon or violate the rights of any other party and that we are not
                        in any way responsible for any such use by you.
                    </p>
                    <p>
                        <strong>
                            Trademarks and Other Intellectual Property of Gem Specialty Health, Inc.
                        </strong>
                    </p>
                    <p>
                        You understand and agree that Gem Specialty Health, Inc. or its Affiliates
                        (&quot;Affiliates&quot; shall mean any entity who directly or indirectly
                        controls, is controlled by or is under common control with such entity) own
                        all right, title, and interest in the intellectual property related to the
                        Online Services, including, without limitation, all trademarks, trade names,
                        copyrights, logos, graphics, software, data and documentation. You also
                        agree not to decompile, disassemble, reverse engineer, modify, copy,
                        relicense, resell, rent, transfer, remove or export any software owned by
                        Gem Specialty Health, Inc. or its Affiliates or agents. Unless you receive
                        prior written consent from us, you are not permitted to use or reproduce any
                        intellectual property owned by Gem Specialty Health, Inc. or its Affiliates
                        or agents. Your use of the services by way of the Online Services is through
                        a non-exclusive right to use subject to the terms of these Terms and
                        Conditions. Any use other than the intended use is prohibited and may
                        subject you to civil and criminal penalties. In addition, this website and
                        all Content contained on the website are the proprietary information of
                        ours, and may not be disseminated without our express written consent.
                    </p>
                    <p>
                        <strong>Copyright Complaints</strong>
                    </p>
                    <ul>
                        <li>
                            We respect the intellectual property of others, and we ask our users to
                            do the same. We may, in appropriate circumstances and at our discretion,
                            suspend or terminate the access of and take other action against users,
                            subscribers, registrants and account holders who infringe the copyright
                            rights of others.
                        </li>
                        <li>
                            If you believe that your work has been copied and is accessible on the
                            Online Services in a way that constitutes copyright infringement, or
                            that the Online Services contain links or other references to another
                            online location that contains material or activity that infringes your
                            copyright rights, you may notify us by providing the following
                            information (as required by the Online Copyright Infringement Liability
                            Limitation Act of the Digital Millennium Copyright Act, 17 U.S.C. sec.
                            512) to our copyright agent set forth below:
                            <ul>
                                <li>
                                    A physical or electronic signature of person authorized to act
                                    on behalf of the owner of an exclusive right that is allegedly
                                    infringed;
                                </li>
                                <li>
                                    Identification of the copyrighted work claimed to have been
                                    infringed, or if multiple copyrighted works at a single online
                                    site are covered by a single notification, a representative list
                                    of such works at that site;
                                </li>
                                <li>
                                    Identification of the material that is claimed to be infringing
                                    or to be the subject of infringing activity and that is to be
                                    removed or access to which is to be disabled, and information
                                    reasonably sufficient to permit us to locate the material;
                                </li>
                                <li>
                                    Information reasonably sufficient to permit us to contact the
                                    complaining party, such as an address, telephone number, and if
                                    available, an electronic mail address at which the complaining
                                    party may be contacted;
                                </li>
                                <li>
                                    A statement that the complaining party has a good faith belief
                                    that use of the material in the manner complained of is not
                                    authorized by the copyright owner, its agent, or the law; and
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        A statement that the information in the notification is accurate, and under
                        penalty of perjury, that the complaining party is authorized to act on
                        behalf of the owner of an exclusive right that is allegedly infringed
                    </p>
                    <p>GEM Health&nbsp;</p>
                    <p>
                        2214 5<sup>th</sup> Street STE 5
                    </p>
                    <p>White Bear Lake, MN 55110</p>
                    <p>
                        <strong>Privacy</strong>
                    </p>
                    <p>
                        We may collect and use certain information about you and your use of the
                        Online Services and our services. The manner in which we handles this data
                        is explained in the Privacy Policy, which is located on the Online Services
                        and is incorporated herein by reference.
                    </p>
                    <p>
                        <strong>Termination or Suspension of Access</strong>
                    </p>
                    <p>
                        We reserves the right to terminate or suspend, at any time and effective
                        immediately, your access to the Online Services and our services.
                    </p>
                    <p>
                        <strong>No Warranties</strong>
                    </p>
                    <p>
                        This site is provided to you on an &quot;as is&quot; and &quot;as
                        available&quot; basis. We makes no representations or warranties of any
                        kind, either express or implied, including but not limited to, implied
                        warranties of merchantability and fitness for a particular purpose. We
                        expressly disclaims liability for any technical problems with the website,
                        including temporary unavailability or delays in connection time. We do not
                        accept responsibility for any illegal pirating of the website or for the
                        actions of those who may obtain unauthorized access to the site. We
                        expressly disclaim all liability and any warranties concerning the Content,
                        products, services, information, or materials made available through this
                        site or its links or any actions that you may take or refrain from taking as
                        a result of any such Content, products, services, information, or materials.
                        Information on this website is presented for informational purposes only and
                        should not be construed as advice to users. Should you have any questions or
                        concerns about any information or material presented on a web site referred
                        to by the Online Services, please contact the author of that information
                        directly.
                    </p>
                    <p>
                        <strong>Limitation on Liability</strong>.
                    </p>
                    <p>
                        TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL GEM SPECIALTY
                        HEALTH, INC.&nbsp;&nbsp;(OR OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD
                        PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE
                        PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
                        PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR
                        INABILITY TO USE, THE ONLINE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE ONLINE SERVICES IS
                        AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY
                        DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING
                        THEREFROM.
                    </p>
                    <p>
                        TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE
                        CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM
                        OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE
                        FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF ONE
                        HUNDRED FIFTY US DOLLARS (U.S. $150). THE EXISTENCE OF MORE THAN ONE CLAIM
                        WILL NOT ENLARGE THIS LIMIT. TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU
                        AGREE THAT OUR SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR
                        RELATING TO THIS AGREEMENT.
                    </p>
                    <p>
                        SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR
                        INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION
                        MAY NOT APPLY TO YOU.
                    </p>
                    <p>
                        <strong>Entire Agreement</strong>
                    </p>
                    <p>
                        These Terms and Conditions and any other policy referenced herein shall
                        constitute the entire agreement between you and us with respect to your use
                        of the Online Services. It shall be governed by and construed in accordance
                        with the internal laws of the State of Minnesota, and any controversy
                        between you and us shall be adjudicated in courts of competent jurisdiction
                        located within Minnesota. By using the Online Services, you submit to the
                        jurisdiction of such courts. No provision of these Terms and Conditions
                        shall be deemed to be waived except expressly in writing signed by an
                        authorized representative of us. Further, by using this site, you agree
                        that, if any provision of these Terms and Conditions are found by a court to
                        be invalid or unenforceable, the court shall modify the provision to the
                        extent necessary consistent with the intent of the parties, and the
                        remaining provisions shall remain in full force and effect. Regardless of
                        any law to the contrary, all claims or causes of action arising out of or
                        related to use of the website must be filed within one (1) year after it
                        arose or shall be deemed to be forever barred.
                    </p>
                    <p>
                        <strong>Modifications</strong>
                    </p>
                    <p>
                        We may, in our sole discretion, modify or revise these Terms and Conditions
                        at any time, and you agree to be bound by such modifications or revisions.
                        The date of the most recent modifications or revisions will appear on this
                        page, so check back often. Continued access of the Online Services by you
                        will constitute your acceptance of any modifications or revisions to these
                        Terms and Conditions.
                    </p>
                    <p>
                        <strong>Inquiries</strong>
                    </p>
                    <p>
                        Please direct any questions regarding the Online Services, these Terms and
                        Conditions or any other policies issued by us by using the contact
                        information located on the Contact Us page.
                    </p>
                </div>
            </Modal>

            {/* Informed Consent Modal shown in Account profile, CreateAccountForm, or search params*/}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('informedConsent') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('informedConsent'));
                }}>
                {consent && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: consent['main-content'],
                        }}></div>
                )}
            </Modal>

            {/* Marketing Modal shown in CreateAccountForm*/}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('marketing') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('marketing'));
                }}>
                <p>
                    I give consent to Gem Health to send me email and/or SMS messages related to Gem
                    Health's promotions, marketing materials, and other important updates. I
                    understand that each such message I receive will provide instructions for how I
                    can stop receiving future messages.
                </p>
            </Modal>

            {/* Toggled by putting in a state we do not service (in addition to validation) */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('wrongState') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('wrongState'));
                }}>
                <div className="space-y-4 text-gray-darker">
                    <h3 className="mb-4 font-heading text-4xl lg:text-7xl">We're so sorry</h3>
                    <p>
                        GEM SLEEP doesn't have a physician in your state to properly diagnose and
                        prescribe treatment at this time.
                    </p>
                    <p>
                        However, our team is growing quickly and by joining our waitlist, we can
                        notify you as soon as we are able to help.
                    </p>
                    <a
                        href="https://mygemsleep.com/waitlist"
                        className="btn-primary !mt-12 block w-full"
                        rel="noreferrer"
                        target="_blank">
                        Join our waitlist
                    </a>
                </div>
            </Modal>

            {/* Shown when date entered during assessment is too young */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('assessmentTooYoung') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('assessmentTooYoung'));
                }}>
                <div className="space-y-4 text-center text-gray-darker">
                    <p className="text-lg lg:text-xl">
                        You must be at least 18 years old to proceed.
                    </p>
                </div>
            </Modal>

            {/* Toggled during assessment if they come through with previous diagnosis */}
            <Modal
                showClose={true}
                isOpen={modalsOpen.indexOf('noReasonListed') > -1}
                closeHandler={() => {
                    dispatch(toggleModal('noReasonListed'));
                    navigate('/');
                }}>
                <p className="mb-4 text-sm lg:text-lg">
                    We are consistently adding new features to the GEM SLEEP offering, please join{' '}
                    <a
                        href="https://mygemsleep.com/waitlist"
                        className="border-b-2 border-gem-green font-semibold"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            navigate('/');
                        }}>
                        our waitlist
                    </a>{' '}
                    to learn more. Within the 'Why Interested in GEM Box', please indicate how we
                    can help you.
                </p>
                <p className="text-sm lg:text-lg">
                    If you need immediate assistance please contact us at{' '}
                    <a href="mailto:info@gem.health" className="link">
                        info@gem.health
                    </a>
                    .
                </p>
            </Modal>
        </>
    );
}

export default UnauthenticatedModals;
