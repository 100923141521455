import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { toggleModal } from '../../../features/ui/uiSlice';
import { hearAboutUsOptions, states } from '../../../utils/constants';
import { CustomCheckbox, CustomReactSelect, CustomInput } from '../../InputComponents';

function CreateAccountForm({ submitHandler, formSubmitting }) {
    const dispatch = useDispatch();

    const createAccountSchema = yup
        .object({
            firstName: yup
                .string()
                .trim('No leading or trailing spaces')
                .strict(true)
                .min(1)
                .required('First name is required'),
            lastName: yup
                .string()
                .trim('No leading or trailing spaces')
                .strict(true)
                .min(1)
                .required('Last name is required'),
            state: yup.string().required('State is required.'),
            email: yup
                .string()
                .email('Must be a valid email address.')
                .required('Email is required.'),
            confirmEmail: yup
                .string()
                .email('Must be a valid email address.')
                .required('You must enter your email twice')
                .equals([yup.ref('email')], 'Emails must match.'),
            phone: yup
                .string()
                .transform((val, oldVal) => {
                    const v = val;
                    return v.replace(/[- )(]/g, '');
                })
                .required('Phone number is required')
                .matches(/^\d{10}$/, 'Phone number must be 10 digits'),
            password: yup
                .string()
                .required('Password is required')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-])(?=.{8,})/,
                    'Must contain 8 characters, one uppercase, one lowercase, one number and one special character (!@#$%^&*-)',
                ),
            confirmPassword: yup
                .string()
                .required('Password confirm is required')
                .equals([yup.ref('password')], 'Passwords must match.'),
            agreeToPrivacy: yup.boolean().oneOf([true], 'You must agree to this'),
            agreeToTerms: yup.boolean().oneOf([true], 'You must agree to this'),
        })
        .required();

    const privacyLabel = () => (
        <>
            I agree to the{' '}
            <button
                className="border-b-2 border-green-400 font-bold text-white"
                onClick={(ev) => {
                    ev.preventDefault();
                    dispatch(toggleModal('notice'));
                }}>
                privacy policy
            </button>
        </>
    );

    const termsLabel = () => (
        <>
            I have read and agree to the{' '}
            <button
                className="border-b-2 border-green-400 font-bold text-white"
                onClick={(ev) => {
                    ev.preventDefault();
                    dispatch(toggleModal('informedConsent'));
                }}>
                Informed Medical Consent
            </button>
        </>
    );

    const marketingLabel = () => (
        <>
            I agree to receive GEM Health{' '}
            <button
                className="border-b-2 border-green-400 font-bold text-white"
                onClick={(ev) => {
                    ev.preventDefault();
                    dispatch(toggleModal('marketing'));
                }}>
                Marketing emails/SMS messages
            </button>
        </>
    );

    return (
        <Formik
            validationSchema={createAccountSchema}
            validateOnChange={true}
            initialValues={{
                firstName: '',
                lastName: '',
                state: '',
                email: '',
                confirmEmail: '',
                phone: '',
                patientReportedInsurance: '',
                password: '',
                confirmPassword: '',
                agreeToPrivacy: false,
                agreeToTerms: false,
                agreeToMarketing: true,
                referralSource: '',
            }}
            onSubmit={submitHandler}>
            {({ dirty, isValid }) => (
                <Form
                    id="gem-create-account"
                    name="gem-create-account"
                    className="ph-no-capture space-y-6">
                    <CustomInput name="firstName" label="First Name" />
                    <CustomInput name="lastName" label="Last Name" />
                    <CustomInput type="email" name="email" label="Email" autoComplete="username" />
                    <CustomInput
                        type="email"
                        name="confirmEmail"
                        label="Confirm your Email"
                        autoComplete="username"
                    />
                    <CustomInput
                        type="password"
                        name="password"
                        label="Password"
                        autoComplete="new-password"
                        className="ph-no-capture"
                    />
                    <CustomInput
                        type="password"
                        name="confirmPassword"
                        label="Confirm password"
                        autoComplete="new-password"
                        className="ph-no-capture"
                    />
                    <CustomReactSelect
                        id="state"
                        name="state"
                        placeholder="State"
                        options={states}
                    />
                    <CustomInput name="phone" label="Mobile Phone" />
                    <CustomReactSelect
                        id="referralSource"
                        name="referralSource"
                        placeholder="Where did you hear about us?"
                        options={hearAboutUsOptions}
                    />
                    <CustomCheckbox
                        name="agreeToPrivacy"
                        id="agreeToPrivacy"
                        label={privacyLabel()}
                    />
                    <CustomCheckbox name="agreeToTerms" id="ageAgreement" label={termsLabel()} />
                    <CustomCheckbox
                        name="agreeToMarketing"
                        id="marketingAgreement"
                        label={marketingLabel()}
                    />
                    <button
                        className="btn-primary w-full"
                        type="submit"
                        disabled={!dirty || !isValid || formSubmitting}>
                        Continue
                    </button>
                </Form>
            )}
        </Formik>
    );
}

export default CreateAccountForm;
