import { useLocation, useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import topRightArrow from '../../images/top-right-arrow.svg';
import { useInsuranceContext } from './InsuranceContext';
import CPAPCoveragePaymentChoiceModal from './CPAPCoveragePaymentChoiceModal';
import { toggleModal } from '../../features/ui/uiSlice';
import { toggleInsurance } from '../../features/cart/cartSlice';

function CPAPGetStartedButton() {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (location?.state?.from === '/dashboard/insurance/add') setModalOpen(true);
    }, [location?.state?.from, setModalOpen]);

    const { hasInsuranceAlternative, insurance } = useInsuranceContext();

    function handleContinueClick() {
        if (!insurance) {
            navigate('/dashboard/treatment/gem-bundle');
        } else if (hasInsuranceAlternative) {
            dispatch(toggleInsurance(true));
            dispatch(toggleModal('maskFit'));
        } else if (
            insurance.payer?.CPAPCovered ||
            insurance.payer?.dmeReferralOrganizationId !== null
        ) {
            navigate('/dashboard/treatment/comparison');
        } else {
            navigate('/dashboard/treatment/gem-bundle');
        }
    }

    return (
        <>
            <button
                className="btn-primary flex items-center justify-between space-x-4 w-full xl:w-[280px] max-h-[68px]"
                onClick={() => {
                    setModalOpen(true);
                }}>
                <span className="pointer-events-none">Get started</span>
                <img src={topRightArrow} alt="" className="w-2 lg:w-3 pointer-events-none" />
            </button>
            {modalOpen && (
                <CPAPCoveragePaymentChoiceModal
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    handleContinueClick={handleContinueClick}
                />
            )}
        </>
    );
}

export default CPAPGetStartedButton;
