import { createSlice } from '@reduxjs/toolkit';
import ReactGA from 'react-ga4';
import { Assessment } from '../../utils/assessment';
import { apneaQuestions, getAgeFromDateString } from '../../utils/constants';

import { getBMI } from '../../utils/constants';
import posthog from 'posthog-js';

const initialState = {
    allowLowScores: false,
    answers: {},
    apneaScore: 0,
    assessmentStep: 'questions', // questions, calculating, result, create-account
    currentQuestionIndex: 0,
    hasHighRisk: false,
    homeSleepStudyStatus: null,
    partnerId: null,
    partnerType: null,
    previouslyDiagnosed: false,
    questions: apneaQuestions,
};

//High risk selections occur if they have not answered 'NONE' on one of the questions
function determineIfHighRiskSelections(answers) {
    if (Array.isArray(answers) && answers.length > 0 && answers.indexOf('NONE') < 0) {
        return true;
    } else {
        return false;
    }
}

export const assessmentSlice = createSlice({
    name: 'assessment',
    initialState: initialState,
    reducers: {
        allowLowScores: (state, { payload }) => {
            state.allowLowScores = payload;
        },
        finishAssessment: (state) => {
            //If they weren't previously diagnosed, show them the calculation animation
            if (!state.previouslyDiagnosed) {
                state.assessmentStep = 'calculating';
            } else {
                //Otherwise take them straight to account creation if their BMI is under 45
                if (!state.hasHighRisk && state.answers.howBroughtToGem !== 'STRUGGLE_WITH_CPAP') {
                    state.assessmentStep = 'create-account';
                } else {
                    //Or show them they have high risk in result step
                    state.assessmentStep = 'result';
                }
            }
        },
        setAnswer: (state, { payload: { answer, answerKey } }) => {
            if (answerKey === 'previouslyDiagnosedWithSleepApnea') {
                //If the user returns all the way to the previouslyDiagnosed question, reset the assessment
                state.questions = JSON.parse(JSON.stringify(apneaQuestions));

                if (answer) {
                    //If the user is previously diagnosed, ask if they have gained or lost 20 pounds
                    const gained20LbsIndex = state.questions.findIndex(
                        (q) => q.answerKey === 'gained20Lbs',
                    );

                    state.questions[gained20LbsIndex].skip = false;

                    //If the user is not previously diagnosed, show the result screen
                }

                //Determine the index of the previously diagnosed questions
                const howBroughtToGemIndex = state.questions.findIndex(
                    (q) => q.answerKey === 'howBroughtToGem',
                );
                const weCanHelpIndex = state.questions.findIndex(
                    (q) => q.answerKey === 'weCanHelp',
                );
                const hasPriorStudyIndex = state.questions.findIndex(
                    (q) => q.answerKey === 'hasPriorStudy',
                );
                const needNewSleepStudyIndex = state.questions.findIndex(
                    (q) => q.answerKey === 'needNewSleepStudy',
                );
                //And skip those questions if not previously diagnosed
                if (state.questions[howBroughtToGemIndex])
                    state.questions[howBroughtToGemIndex].skip = !answer;
                if (state.questions[weCanHelpIndex]) state.questions[weCanHelpIndex].skip = !answer;
                if (state.questions[hasPriorStudyIndex])
                    state.questions[hasPriorStudyIndex].skip = !answer;
                if (state.questions[needNewSleepStudyIndex])
                    state.questions[needNewSleepStudyIndex].skip = !answer;

                //Set to state whether they were previously diagnosed
                state.previouslyDiagnosed = answer;
            }

            //Determinations when the user tells us how they were brought to GEM
            else if (answerKey === 'howBroughtToGem') {
                //If the user is looking for coaching with their current CPAP, don't skip the extra exclusionary questions
                const highRiskSelection2QuestionIndex = state.questions.findIndex(
                    (q) => q.answerKey === 'highRiskSelections2',
                );

                state.questions[highRiskSelection2QuestionIndex].skip =
                    answer !== 'STRUGGLE_WITH_CPAP';

                //If the user needs a new sleep test, skip the hasPriorStudy and needNewSleepStudy questions
                state.questions[
                    state.questions.findIndex((q) => q.answerKey === 'hasPriorStudy')
                ].skip = answer === 'NEED_NEW_SLEEP_TEST';

                state.questions[
                    state.questions.findIndex((q) => q.answerKey === 'needNewSleepStudy')
                ].skip = answer === 'NEED_NEW_SLEEP_TEST';
            }

            //If they answer yes to the first question about stopping breathing, skip the second question.
            else if (answerKey === 'doesStopBreathing') {
                state.questions[state.currentQuestionIndex + 1].skip = answer;
            }

            //If the user has a prior study, skip the need new sleep study question
            else if (answerKey === 'hasPriorStudy') {
                state.questions[
                    state.questions.findIndex((q) => q.answerKey === 'needNewSleepStudy')
                ].skip = answer;
            }

            //If they have gained or lost 20 pounds, show them the situationChanged question
            else if (answerKey === 'gained20Lbs') {
                state.questions[
                    state.questions.findIndex((q) => q.answerKey === 'situationChanged')
                ].skip = !answer || state.answers.howBroughtToGem === 'STRUGGLE_WITH_CPAP';
            }

            //Set the answer to state for calculation and submission
            if (answerKey) state.answers[answerKey] = answer;

            if (typeof answerKey !== 'undefined') {
                switch (answerKey) {
                    case 'dateOfBirth':
                    case 'heightInches':
                    case 'weightLbs':
                        ReactGA.event({
                            category: 'User',
                            action: 'Answered a Question',
                            label: `${answerKey}_true`,
                        });
                        break;
                    case 'highRiskSelections':
                        ReactGA.event({
                            category: 'User',
                            action: 'Answered a Question',
                            label: `${answerKey}_${
                                Array.isArray(answer) ? answer.join(',') : answer
                            }`,
                        });
                        break;
                    default:
                        ReactGA.event({
                            category: 'User',
                            action: 'Answered a Question',
                            label: `${answerKey}_${state.answers[answerKey]}`,
                        });
                }
            }

            //Calculate age, bmi, and compute stopbang score to determine if they have apnea based on current questions
            const age = getAgeFromDateString(state.answers.dateOfBirth);
            const bmi = getBMI(state.answers.weightLbs, state.answers.heightInches);

            state.apneaScore = Assessment.computeStopBangScore(state.answers, age, bmi);

            //On each of the two high risk selection questions, determine if they have high risk selections
            if (answerKey === 'highRiskSelections' || answerKey === 'highRiskSelections2') {
                state.hasHighRisk = determineIfHighRiskSelections(answer);
            } else {
                state.hasHighRisk = bmi >= 45;
            }

            if (state.allowLowScores) {
                //Set the low score question skip based on current score, only ask for scores of 1 or 2
                const lowScoreAdditionsQuestionIndex = state.questions.findIndex(
                    (q) => q.answerKey === 'lowScoreAdditions',
                );
                state.questions[lowScoreAdditionsQuestionIndex].skip = ![1, 2].includes(
                    state.apneaScore,
                );
            }

            //If they have high risk selections proceed to result screen
            if (state.hasHighRisk) {
                state.assessmentStep = 'result';
            }
        },
        nextQuestion: (state, { payload }) => {
            //Determine the current question index based on payload
            const currentQuestionIndex = state.questions.findIndex((q) => q.answerKey === payload);
            state.currentQuestionIndex = currentQuestionIndex + 1;

            while (state.questions[state.currentQuestionIndex]?.skip) state.currentQuestionIndex++;
        },
        previousQuestion: (state, { payload }) => {
            //Clear out any answers that might have been set previously
            if (!['weCanHelp', 'needNewSleepStudy'].includes(payload)) {
                state.answers[payload] = null;
            }

            state.currentQuestionIndex = state.currentQuestionIndex - 1;

            while (state.questions[state.currentQuestionIndex].skip) state.currentQuestionIndex--;
        },
        setAssessmentStep: (state, { payload }) => {
            state.assessmentStep = payload;
        },
        reset: (state) => {
            state.apneaScore = 0;
            state.assessmentStep = 'questions';
            state.currentQuestionIndex = 0;
            state.hasHighRisk = false;
            state.questions = apneaQuestions;
        },
        setPartner: (state, { payload: { id, type } }) => {
            state.partnerId = id;
            state.partnerType = type;

            posthog?.capture('Entered portal through partner link', {
                partnerId: id,
                partnerType: type,
            });
        },
        setSleepStudyStatus: (state, { payload }) => {
            state.homeSleepStudyStatus = payload;
        },
    },
});

export const {
    allowLowScores,
    finishAssessment,
    nextQuestion,
    previousQuestion,
    reset,
    setAnswer,
    setAssessmentStep,
    setPartner,
    setSleepStudyStatus,
} = assessmentSlice.actions;

export default assessmentSlice.reducer;

export const selectAssessmentState = (state) => state.assessment;
export const selectCurrentQuestionData = (state) =>
    state.assessment.questions[state.assessment.currentQuestionIndex];
