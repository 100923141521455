import { useDispatch } from 'react-redux';
import { toggleModal } from '../../features/ui/uiSlice';
import { ReactComponent as ThumbsUp } from '../../images/thumbs-up.svg';
import { ReactComponent as ThumbsDown } from '../../images/thumbs-down.svg';
import { ReactComponent as FeedbackIcon } from '../../images/icons/feedback.svg';

export const FeedbackBox = () => {
    const dispatch = useDispatch();
    return (
        <div className="bg-purple-bright p-5 lg:flex lg:items-center lg:justify-between lg:p-7">
            <div className="relative pl-14">
                <FeedbackIcon className="absolute left-0 top-1/2 -translate-y-1/2 text-white" />
                <p className="font-heading text-xs tracking-widest text-gray-neutral lg:text-sm">
                    your feedback is very important to us.
                </p>
                <p className="font-bold text-white lg:text-xl">How are we doing?</p>
            </div>
            <div className="ml-auto flex items-center space-x-2">
                <span className="text-gray-neutral">Select:</span>
                <button
                    className="relative h-9 w-9 rounded-full border bg-white transition hover:border-green-400"
                    onClick={() => {
                        dispatch(toggleModal('goodRating'));
                    }}>
                    <ThumbsUp className="absolute-center pointer-events-none" />
                </button>
                <button
                    className="relative h-9 w-9 rounded-full border bg-white transition hover:border-green-400"
                    onClick={() => {
                        dispatch(toggleModal('badRating'));
                    }}>
                    <ThumbsDown className="absolute-center pointer-events-none" />
                </button>
            </div>
        </div>
    );
};
