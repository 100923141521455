import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import posthog from 'posthog-js';

import { useCheckoutContext } from '../../Checkout/CheckoutContext';
import { useInsuranceContext } from '../InsuranceContext';
import { useGetPayersQuery } from '../../../app/services/insurance';
import Modal from '../../Modal/Modal';
import { overlayVariants } from '../../../utils/constants';
import { toggleInsurance } from '../../../features/cart/cartSlice';
import { useApplyDiscountToCartMutation } from '../../../app/services/cart';

function InsuranceOption() {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const { setCheckoutStep } = useCheckoutContext();
    const { setChosenPayer } = useInsuranceContext();
    const [applyDiscount] = useApplyDiscountToCartMutation();
    const { data: payers } = useGetPayersQuery();
    const { lowScoreConsultRequired } = useSelector((state) => state.cart);

    return (
        <div className="space-y-4">
            <p>
                GEM SLEEP is in network with many major insurers. Select your insurance to check
                eligibility for the home sleep test.
            </p>
            {payers
                ?.filter((payer) => payer.name.toLowerCase() !== 'other')
                .map((payer) => (
                    <button
                        key={payer.id + 'button'}
                        className="btn-primary bg-gem-green w-full"
                        onClick={() => {
                            dispatch(toggleInsurance(true));
                            setChosenPayer(payer);
                            setCheckoutStep('insurance-details');

                            posthog?.capture('Chose payer', { payer: payer.name });
                        }}>
                        {payer.name}
                    </button>
                ))}
            <button
                className="btn-shell w-full bg-gray-neutral"
                onClick={() => {
                    setModalOpen(true);

                    posthog?.capture('Payer Not Listed');
                }}>
                My plan isn't listed
            </button>
            <Modal showClose={true} isOpen={modalOpen} closeHandler={() => setModalOpen(false)}>
                <p className="mb-4">
                    We are always working to add more coverage. Often paying out of pocket is less
                    expensive than with insurance. Continue with no insurance and receive the home
                    sleep test for the discounted price of $149 ($40 discount).
                </p>
                <button
                    className="btn-primary w-full"
                    onClick={async () => {
                        posthog?.capture('Payer Not Listed - Continue with Cash Pay');

                        await applyDiscount('STUDY40');

                        dispatch(toggleInsurance(false));
                        //If they need an Intial Consultation PLUS consult (low score) then send them there, otherwise to ordering
                        setCheckoutStep(
                            lowScoreConsultRequired ? 'required-consult-booking' : 'address-page',
                        );
                    }}>
                    Continue
                </button>
            </Modal>
        </div>
    );
}

function CashpayOption() {
    const { setCheckoutStep } = useCheckoutContext();

    return (
        <div className="space-y-4 text-xl">
            <p>
                <s className="text-red">$377</s> <span className="font-bold">$189</span>
            </p>
            <p>Includes Free Shipping!</p>
            <button
                className="btn-primary w-full"
                onClick={() => {
                    posthog?.capture(
                        lowScoreConsultRequired
                            ? 'Continue to Required Consult Booking'
                            : 'Continue to Shipping',
                    );
                    //If they need an Intial Consultation PLUS consult (low score) then send them there, otherwise to ordering
                    setCheckoutStep(
                        lowScoreConsultRequired ? 'required-consult-booking' : 'address-page',
                    );
                }}>
                Continue
            </button>
        </div>
    );
}

function PaymentChoice() {
    const [tab, setTab] = useState('insurance');

    return (
        <motion.div initial="hidden" animate="visible" exit="hidden" variants={overlayVariants}>
            <h5 className="mb-5 font-heading text-4xl font-bold lg:text-5xl text-center">
                Next Up: Choose How You Want to Pay
            </h5>
            <nav className="my-4 lg:my-8 text-center">
                <button
                    onClick={(ev) => {
                        setTab('insurance');
                    }}
                    className={`lg:text-xl border-black border-b py-2 min-w-[150px] lg:min-w-[220px] ${tab === 'insurance' ? 'text-gem-green border-gem-green! font-bold border-b-[2px]' : 'hover:text-gem-green hover:font-bold'}`}>
                    INSURANCE
                </button>
                <button
                    onClick={(ev) => {
                        setTab('no-insurance');
                    }}
                    className={`lg:text-xl border-black border-b py-2 min-w-[150px] lg:min-w-[220px] ${tab === 'no-insurance' ? 'text-gem-green border-gem-green! font-bold border-b-[2px]' : 'hover:text-gem-green hover:font-bold'}`}>
                    NO INSURANCE
                </button>
            </nav>
            {tab === 'insurance' && <InsuranceOption />}
            {tab === 'no-insurance' && <CashpayOption />}
        </motion.div>
    );
}

export default PaymentChoice;
