import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { formatSquarePrice, overlayVariants } from '../../../utils/constants';
import { useCheckoutContext } from '../../Checkout/CheckoutContext';

const NotEligible = () => {
    const { subtotal, cpapInCart, hstInCart, isConsultOrder } = useSelector((state) => state.cart);
    const { skipInsurance } = useCheckoutContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getPromoText = () => {
        if (hstInCart) {
            return <b>Take $20 off your Home Sleep Study Purchase with code: STUDY20.</b>;
        } else if (cpapInCart) {
            return <b className="text-red">Take $150 off your CPAP purchase with code: 150CPAP.</b>;
        } else if (!isConsultOrder) {
            // note: assuming if it is not sleep study, not cpap, and not consult, it is supplies
            return (
                <b className="text-red">Use Code 20SAVINGS to take 20% off your supplies order.</b>
            );
        }
    };

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={overlayVariants}
            className="space-y-5">
            <h5 className="mb-5 font-heading text-4xl font-bold lg:text-5xl">
                Unfortunately your insurance doesn't yet cover these GEM SLEEP services.
            </h5>
            <p className="text-sm lg:text-base">
                We are actively working with health plans to expand coverage for GEM's services. You
                can still use your HSA/FSA dollars towards this purchase.
            </p>
            <p>
                Your total cost will be: <br />
                <span className="font-heading text-3xl font-bold lg:text-5xl">
                    ${formatSquarePrice(subtotal)}
                </span>
                <br />
                <br />
                {getPromoText()}
            </p>
            <button
                className="btn-primary w-full"
                onClick={() => {
                    skipInsurance();
                }}>
                Continue to checkout
            </button>
            <p className="text-sm text-gray-six lg:text-base">
                <b>Concerned about not using your insurance?</b> We get it! We are working with
                health plans to bring affordable sleep apnea solutions to their networks. The cost
                with other providers may be more than GEM SLEEP's cost. You can check your health
                plans cost estimator to see other covered options. And with most health plans, you
                are responsible for the entire cost out of pocket until reaching your deductible.
            </p>
        </motion.div>
    );
};

export default NotEligible;
